import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal as MuiModal,
  IconButton,
  Button,
  TextField,
  Stack,
  Chip,
  Tooltip
} from '@mui/material'
import { TbBrandGoogleAnalytics } from 'react-icons/tb'
import { useAnalyticsHooks } from './useAnalyticsHooks'
import { BarChart } from '@mui/x-charts/BarChart'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import { Close } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { LineChart } from '@mui/x-charts'
import { UserContext } from 'context/AuthContext/UserContext'
import CustomisedInput from 'components/formElements/CustomisedInput'
import { BiImport } from 'react-icons/bi'
import { exportJsonToCsv } from 'shared/util/helper'
import CommonTableSku from './common/skuTable'
import CommonTableComponent from './common/shopTable'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import Modal from 'components/uiElements/modal/Dialog'
import AnalyticTemplate from 'components/uiElements/analytics/analytics'
import { GrPowerReset } from 'react-icons/gr'
import DataBoxGrid from './common/DataBoxGrid'
// import CustomTooltip from './common/CustomTooltip'
import { IoFilter } from 'react-icons/io5'
import CommonLoader from './common/commonLoader'
import { BsGraphDownArrow, BsGraphUpArrow } from 'react-icons/bs'
import Seo from 'components/common/seo'

const AnalyticsIndex = () => {
  const {
    sales,
    totalRevenueLoad,
    setSelectedOption,
    setSkuSelectedOption,
    skuSelectedOption,
    selectedOption,
    shops,
    shopsSku,
    shipment,
    setSelectedYear,
    selectedYear,
    shop,
    wareHouse,
    filteredSkus,
    selectedPercent,
    setSelectedPercent,
    setSelectedShop,
    selectedShop,
    setSelectedShipmentCountry,
    selectedShipmentCountry,
    monthToDateGraphShop,
    weekToDateGraphShop,
    selectedSkuFilter,
    setSelectedSkuFilter,
    yearToDateGraphShop,
    monthToDateGraphCountry,
    weekToDateGraphCountry,
    yearToDateGraphCountry,
    isLoadingMonthShop,
    isLoadingWeekShop,
    setSelectedShipmentCountrySku,
    selectedShipmentCountrySku,
    isLoadingYearShop,
    isLoadingMonthCountry,
    yearSelectStartDate,
    setYearSelectStartDate,
    yearSelectEndDate,
    setYearSelectEndDate,
    fetchDataPaticularShop,
    shopCountryData,
    shopCountryDataLoading,
    isLoadingWeekCountry,
    isLoadingYearCountry,
    currentYearMonthToDateGraphShop,
    currentYearMonthToDateGraphCountry,
    multipleShopNames,
    setMultipleShopNames,
    currentYearWeekToDateGraphShop,
    currentYearWeekToDateGraphCountry,
    isLoadingCustomShop,
    isLoadingCustomCountry,
    customGraphShop,
    customGraphCountry,
    customGraphShopCurrentYear,
    customGraphCountryCurrentYear,
    skuLoading,
    handleResetFilters
  } = useAnalyticsHooks()

  const [clickedRows, setClickedRows] = useState({})
  const [selectedPdfType, setSelectedPdfType] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)
  const { loading } = useContext(UserContext)
  const onChangeFilter = (name, type) => {
    let dateChanged = new Date(type)
    if (type) {
      setPage(0)
      newParam.set(name, dateChanged)
    } else {
      newParam.delete(name)
      newParam.delete('end_date')
      newParam.delete('sku_end_date')
    }
    navigate({ search: newParam.toString() })
  }
  const onChangeRouter = (key, value) => {
    const Param = new URLSearchParams(location.search)
    Param.set(key, value)
    if (!value) {
      Param.delete(key)
    }
    navigate({ search: Param.toString() })
  }
  const Growth = sales.year?.totalUntaxedTotal > sales.lyear?.totalUntaxedTotal ? true : false
  const yearDifference = sales.year?.totalUntaxedTotal - sales.lyear?.totalUntaxedTotal
  const yearUnitDifference = sales.year?.totalQuantity - sales.lyear?.totalQuantity
  const RangeyearDifference = sales.rangeFilter?.totalUntaxedTotal - sales.lastYearRangeFilterData?.totalUntaxedTotal
  const RangeyearUnitDifference = sales.rangeFilter?.totalQuantity - sales.lastYearRangeFilterData?.totalQuantity
  const monthDifference = sales.month?.totalUntaxedTotal - sales.lmonth?.totalUntaxedTotal
  const monthUnitDifference = sales.month?.totalQuantity - sales.lmonth?.totalQuantity
  const weekDifference = sales.week?.totalUntaxedTotal - sales.lweek?.totalUntaxedTotal
  const weekUnitDifference = sales.week?.totalQuantity - sales.lweek?.totalQuantity
  const monthCurrentDifference = sales.month?.totalUntaxedTotal - sales.prevMonthData?.totalUntaxedTotal
  const weekCurrentDifference = sales.week?.totalUntaxedTotal - sales.lastMonthWeekData?.totalUntaxedTotal
  const monthCurrentUnitDifference = sales.month?.totalQuantity - sales.prevMonthData?.totalQuantity
  const weekCurrentUnitDifference = sales.week?.totalQuantity - sales.lastMonthWeekData?.totalQuantity
  const [showPdf, setShowPdf] = useState(false)
  const [page, setPage] = React.useState(0)
  const [skupage, setSkuPage] = React.useState(0)
  const [xLabels, setXlabels] = useState([])
  const [pData, setPdata] = useState([])
  const [uData, setUdata] = useState([])
  const [xLabelsShop, setXlabelsShop] = useState([])
  const [pDataShop, setPdataShop] = useState([])
  const [uDataShop, setUdataShop] = useState([])
  const [open, setOpen] = useState(false)
  const [openSecond, setOpenSecond] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenSecond = () => setOpenSecond(true)
  const handleCloseSecond = () => setOpenSecond(false)
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleSkuChangePage = (event, newPage) => {
    setSkuPage(newPage)
  }

  async function getMaxDateTwoDaysBefore() {
    const currentDate = new Date()
    const twoDaysBefore = new Date(currentDate)
    twoDaysBefore.setDate(currentDate.getDate() - 2)
    return twoDaysBefore.toISOString().split('T')[0]
  }

  const currentYear = new Date().getFullYear()
  const lastYear = currentYear - 1
  const monthNames = ['JAN', 'FEB ', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  const currentMonthIndex = new Date().getMonth()
  const currentMonth = monthNames[currentMonthIndex]
  const lastMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1
  const lastMonth = monthNames[lastMonthIndex]
  const currentDate = new Date()
  let previousMonth = null
  const monthLabels = []
  const getMonthName = (month) => {
    return monthNames[month]
  }
  for (let month = 0; month <= currentDate.getMonth(); month++) {
    const currentMonth = (currentDate.getMonth() - month + 12) % 12
    const currentMonthLabel = `${getMonthName(currentMonth)}`
    if (currentMonthLabel !== previousMonth) {
      monthLabels.unshift(currentMonthLabel) // Add to the beginning of the array to maintain order
      previousMonth = currentMonthLabel
    }
  }

  const [chartData, setChartData] = useState({ currentYearData: [], lastYearData: [], xLabels: [] })
  const checkdata = () => {
    try {
      let Data = monthLabels.map((label, index) => ({
        name: label,
        CurrentYear: sales?.currentYearData[index]?.totalQuantity,
        LastYear: sales?.lastYearMonthWiseData[index]?.totalQuantity
      }))
      return Data
    } catch (error) {
    } finally {
    }
  }
  const memoizedChartData = useMemo(() => {
    const { currentYearData, lastYearData, xLabels } = chartData
    return {
      currentYearData: currentYearData.length > 0 ? currentYearData : [],
      lastYearData: lastYearData.length > 0 ? lastYearData : [],
      xLabels: xLabels.length > 0 ? xLabels : []
    }
  }, [chartData])

  const ExportData = async (data, type) => {
    if (type === 'sku_shops') {
      data = await data?.filter((key) => delete key.shipment_country)

      return exportJsonToCsv(data, 'SKU Shops')
    } else {
      data = await data?.filter((key) => delete key.flat_image)
      data = await data?.filter((key) => delete key?.sku)
      data = await data?.filter((key) => delete key.shipment_country)
      return exportJsonToCsv(data, 'Shops')
    }
  }
  const dataBoxItems = [
    {
      title: 'WTD Sales',
      amount: sales.week?.totalUntaxedTotal,
      difference: selectedYear === 'lastYear' ? weekDifference : weekCurrentDifference,
      isSales: true
    },
    {
      title: 'MTD Sales',
      amount: sales.month?.totalUntaxedTotal,
      difference: selectedYear === 'lastYear' ? monthDifference : monthCurrentDifference,
      isSales: true
    },
    {
      title: 'YTD Sales',
      amount: sales.year?.totalUntaxedTotal,
      difference: yearDifference,
      disabled: selectedYear === 'currentYear',
      isSales: true
    },
    {
      title: 'WTD Units',
      amount: sales.week?.totalQuantity,
      difference: selectedYear === 'lastYear' ? weekUnitDifference : weekCurrentUnitDifference,
      isSales: false
    },
    {
      title: 'MTD Units',
      amount: sales.month?.totalQuantity,
      difference: selectedYear === 'lastYear' ? monthUnitDifference : monthCurrentUnitDifference,
      isSales: false
    },
    {
      title: 'YTD Units',
      amount: sales.year?.totalQuantity,
      difference: yearUnitDifference,
      disabled: selectedYear === 'currentYear',
      isSales: false
    }
  ]
  const rangeFilterItems = [
    {
      title: 'Sales',
      isSales: true,
      difference: RangeyearDifference,
      disabled: selectedYear === 'currentYear',
      amount: sales.rangeFilter?.totalUntaxedTotal
    },
    {
      title: 'Units',
      isSales: false,
      difference: RangeyearUnitDifference,
      disabled: selectedYear === 'currentYear',
      amount: sales.rangeFilter?.totalQuantity
    }
  ]

  const calculateShopSalesData = async () => {
    const startDate = newParam.get('start_date')
    const endDate = newParam.get('end_date')
    const isCurrentYear = selectedYear === 'currentYear'
    const getSalesData = async () => {
      if (isCurrentYear) {
        if (selectedOption === 'monthToDate') {
          return [currentYearMonthToDateGraphShop?.currentYearSales, currentYearMonthToDateGraphShop?.previousYearSales]
        } else if (selectedOption === 'weekToDate' && !startDate && !endDate) {
          return [currentYearWeekToDateGraphShop?.currentYearSales, currentYearWeekToDateGraphShop?.previousYearSales]
        } else if (startDate && endDate) {
          return [customGraphShopCurrentYear?.currentYearSales, customGraphShopCurrentYear?.previousYearSales]
        }
      } else {
        if (selectedOption === 'yearToDate') {
          return [yearToDateGraphShop?.currentYearSales, yearToDateGraphShop?.previousYearSales]
        } else if (selectedOption === 'monthToDate') {
          return [monthToDateGraphShop?.currentYearSales, monthToDateGraphShop?.previousYearSales]
        } else if (selectedOption === 'weekToDate' && !startDate && !endDate) {
          return [weekToDateGraphShop?.currentYearSales, weekToDateGraphShop?.previousYearSales]
        } else if (startDate && endDate) {
          return [customGraphShop?.currentYearSales, customGraphShop?.previousYearSales]
        }
      }
      return [[], []]
    }
    const [currentYearSales, previousYearSales] = await getSalesData()
    const salesMap = {}
    const addSalesToMap = (sales, yearKey) => {
      sales?.forEach(({ shop, total_qty_ordered }) => {
        if (shop) {
          if (!salesMap[shop]) {
            salesMap[shop] = { name: shop, CurrentYear: 0, LastYear: 0 }
          }
          salesMap[shop][yearKey] = total_qty_ordered
        }
      })
    }
    addSalesToMap(currentYearSales, 'CurrentYear')
    addSalesToMap(previousYearSales, 'LastYear')
    const data = Object.values(salesMap).filter((entry) => entry.name)
    const xLabels1 = []
    const uData1 = []
    const pData1 = []
    const processData = (salesData) => {
      salesData?.forEach((entry, index) => {
        if (!openSecond && index >= 5) return
        xLabels1.push(entry.name)
        uData1.push(entry.CurrentYear)
        pData1.push(entry.LastYear)
      })
    }
    if (multipleShopNames.length) {
      multipleShopNames?.forEach((shop) => {
        if (salesMap[shop]) {
          xLabels1.push(shop)
          uData1.push(salesMap[shop].CurrentYear)
          pData1.push(salesMap[shop].LastYear)
        }
      })
    } else {
      processData(data)
    }
    setXlabelsShop(xLabels1)
    setPdataShop(pData1)
    setUdataShop(uData1)
  }
  const calculateSkuSalesData = async () => {
    const startDate = newParam.get('sku_start_date')
    const endDate = newParam.get('sku_end_date')
    const fetchSalesData = async (selectedYear, selectedOption, startDate, endDate) => {
      if (selectedYear === 'currentYear') {
        if (selectedOption === 'monthToDate') {
          return [
            currentYearMonthToDateGraphCountry?.currentYearSales,
            currentYearMonthToDateGraphCountry?.previousYearSales
          ]
        } else if (selectedOption === 'weekToDate' && !startDate && !endDate) {
          return [
            currentYearWeekToDateGraphCountry?.currentYearSales,
            currentYearWeekToDateGraphCountry?.previousYearSales
          ]
        } else if (startDate && endDate) {
          return [customGraphCountryCurrentYear?.currentYearSales, customGraphCountryCurrentYear?.previousYearSales]
        }
      } else {
        if (selectedOption === 'yearToDate') {
          return [yearToDateGraphCountry?.currentYearSales, yearToDateGraphCountry?.previousYearSales]
        } else if (selectedOption === 'monthToDate') {
          return [monthToDateGraphCountry?.currentYearSales, monthToDateGraphCountry?.previousYearSales]
        } else if (selectedOption === 'weekToDate' && !startDate && !endDate) {
          return [weekToDateGraphCountry?.currentYearSales, weekToDateGraphCountry?.previousYearSales]
        } else if (startDate && endDate) {
          return [customGraphCountry?.currentYearSales, customGraphCountry?.previousYearSales]
        }
      }
      return [[], []]
    }
    const [currentYearSales, previousYearSales] = await fetchSalesData(selectedYear, selectedOption, startDate, endDate)
    const salesMap = {}
    // Add sales to the map
    const addSalesToMap = async (sales, yearKey) => {
      await sales?.forEach(({ shipment_country, total_qty_ordered }) => {
        if (shipment_country) {
          if (!salesMap[shipment_country]) {
            salesMap[shipment_country] = { name: shipment_country, CurrentYear: 0, LastYear: 0 }
          }
          salesMap[shipment_country][yearKey] = total_qty_ordered
        }
      })
    }
    addSalesToMap(currentYearSales, 'CurrentYear')
    addSalesToMap(previousYearSales, 'LastYear')
    const data = Object.values(salesMap).filter((entry) => entry.name)
    const xLabels1 = []
    const uData1 = []
    const pData1 = []
    const processData = (salesData) => {
      salesData.forEach((entry, index) => {
        if (!open && index >= 5) return
        xLabels1.push(entry.name)
        uData1.push(entry.CurrentYear)
        pData1.push(entry.LastYear)
      })
    }

    processData(data)
    setXlabels(xLabels1)
    setPdata(pData1)
    setUdata(uData1)
  }
  useEffect(() => {
    const data = checkdata()
    if (data) {
      const currentYearData = data.map((d) => d.CurrentYear)
      const lastYearData = data.map((d) => d.LastYear)
      const xLabels = data.map((d) => d.name)
      setChartData({ currentYearData, lastYearData, xLabels })
    }
  }, [sales])
  useEffect(() => {
    calculateSkuSalesData()
  }, [
    yearToDateGraphCountry,
    monthToDateGraphCountry,
    weekToDateGraphCountry,
    currentYearMonthToDateGraphCountry,
    currentYearWeekToDateGraphCountry,
    customGraphCountry,
    skuSelectedOption,
    open,
    selectedYear,
    newParam.get('sku_start_date'),
    newParam.get('sku_end_date')
  ])
  useEffect(() => {
    calculateShopSalesData()
  }, [
    yearToDateGraphShop,
    monthToDateGraphShop,
    weekToDateGraphShop,
    currentYearMonthToDateGraphShop,
    currentYearWeekToDateGraphShop,
    customGraphShop,
    openSecond,
    selectedOption,
    selectedYear,
    newParam.get('start_date'),
    newParam.get('end_date'),
    multipleShopNames
  ])

  return (
    <>
      <Seo
        title='Sales Report'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />

      <section className='page-spacing'>
        <div className='container-fluid'>
          <div className='common-card rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid>
                <h3 className='fw-bold advertisement-report'>
                  <TbBrandGoogleAnalytics size={'2rem'} className='me-2' />
                  Sales Report<span></span>
                </h3>
                {totalRevenueLoad ? (
                  ''
                ) : (
                  <p className='fw-semibold'>
                    {sales.year?.totalUntaxedTotal
                      ? `€ ${sales.year?.totalUntaxedTotal?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : ''}{' '}
                    <button
                      className='ms-2 rounded-3 growth'
                      style={{ backgroundColor: Growth ? '#34C085' : '#FF0000' }}
                    >
                      Growth{' '}
                    </button>
                    {Growth ? (
                      <BsGraphUpArrow className='ms-2' fontSize={'1.5rem'} color='#34C085' />
                    ) : (
                      <BsGraphDownArrow className='ms-2' fontSize={'1.5rem'} color='#FF0000' />
                    )}
                  </p>
                )}
              </Grid>
              {/* FILTER INPUTS */}
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return `Select Year`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      value={selectedYear === 'lastYear' ? `Previous Year` : `Current Year`}
                      onChange={(event) => setSelectedYear(event.target.value)}
                    >
                      <MenuItem value='lastYear'>Previous Year</MenuItem>
                      <MenuItem value='currentYear'>Current Year</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={yearSelectStartDate}
                      onChange={(e) => setYearSelectStartDate(e.target.value)}
                      max={getMaxDateTwoDaysBefore()}
                      disabled={false}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={yearSelectEndDate}
                      disabled={!yearSelectStartDate}
                      onChange={(e) => setYearSelectEndDate(e.target.value)}
                      min={yearSelectStartDate}
                      max={getMaxDateTwoDaysBefore()}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.0}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return `PDF Export`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      value={selectedPdfType}
                      onChange={(e) => {
                        setSelectedPdfType(e.target.value)
                        setShowPdf(true)
                      }}
                    >
                      <MenuItem value='shopData'>Shop Based Data</MenuItem>
                      <MenuItem value='skuData'>Sku Based Data</MenuItem>
                      <MenuItem value='allData'>All Data</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam?.get('revenue_warehouse') || ''}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Warehouse</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeRouter('revenue_warehouse', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Warehouse</MenuItem>
                      {wareHouse?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam?.get('commerce_type') || ''}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Commerce Type'
                        }
                        const menuItems = [
                          { value: 'ecomm', label: 'Ecomm' },
                          { value: 'false', label: 'Non Ecomm' }
                        ]

                        const selectedItem = menuItems.find((item) => item.value === selected)
                        return selectedItem ? selectedItem.label : 'Commerce Type'
                      }}
                      onChange={(e) => {
                        onChangeRouter('commerce_type', e.target.value)
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value='ecomm'>Ecomm</MenuItem>
                      <MenuItem value='false'>Non Ecomm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      setYearSelectEndDate(''), setYearSelectStartDate(''), navigate({ pathname: `/analytics` })
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
                <Modal
                  fullWidth
                  maxWidth='md'
                  open={showPdf}
                  title='Sales Data'
                  content={
                    <AnalyticTemplate
                      sales={sales}
                      shops={shops}
                      shopsSku={shopsSku}
                      shopCountryData={shopCountryData}
                      selectedYear={selectedYear}
                      yearSelectStartDate={newParam.get('start_date')}
                      yearSelectEndDate={newParam.get('end_date')}
                      selectedShipmentCountry={selectedShipmentCountry}
                      selectedOption={selectedOption}
                      searchShop={newParam.get('search_shop')}
                      weekSales={sales?.week?.totalUntaxedTotal}
                      monthSales={sales?.month?.totalUntaxedTotal}
                      yearSales={sales?.year?.totalUntaxedTotal}
                      selectedPdfType={selectedPdfType}
                      skuSelectedOption={skuSelectedOption}
                      searchSku={newParam.get('search_sku')}
                      selectedShop={selectedShop}
                      selectedShipmentCountrySku={selectedShipmentCountrySku}
                      selectedPercent={selectedPercent}
                      skuYearSelectStartDate={newParam.get('sku_start_date')}
                      skuYearSelectEndDate={newParam.get('sku_end_date')}
                    />
                  }
                  handleClose={() => {
                    setShowPdf(false)
                    setSelectedPdfType('')
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <section className='dashboard-graph mt-4 mb-2'>
            <div className='row gy-3'>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex  flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2 mb-sm-0 mb-lg-2'>Untaxed Total</h5>
                  </div>
                  <div className='common-card-content'>
                    <Grid item xs={12} lg={6} md={6} sm={6}>
                      {totalRevenueLoad ? (
                        <CommonLoader title={'Loading Total Revenue'} />
                      ) : (
                        <DataBoxGrid
                          data={dataBoxItems}
                          rangeData={rangeFilterItems}
                          showRangeFilter={yearSelectEndDate && yearSelectStartDate}
                          rangeFilterTitle={`Range Filter: ${yearSelectStartDate} - ${yearSelectEndDate}`}
                        />
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2  mb-sm-0 mb-lg-2'>Graph Analysis</h5>
                  </div>
                  <div className='common-card-content'>
                    {/* Chart Component */}
                    {totalRevenueLoad ? (
                      <CommonLoader title={'Loading Graph Analysis'} />
                    ) : (
                      <Grid className='advertisement-graph' item xs={12} lg={6} md={6} sm={6}>
                        <LineChart
                          width={600}
                          height={300}
                          series={[
                            {
                              type: 'line',
                              label: 'Current Year',
                              data: memoizedChartData.currentYearData
                            },
                            {
                              type: 'line',
                              label: 'Previous Year',
                              color: '#B800D8',
                              data: memoizedChartData.lastYearData
                            }
                          ]}
                          xAxis={[
                            {
                              data: memoizedChartData.xLabels,
                              scaleType: 'point',
                              id: 'x-axis-id'
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* FILTER SHOP LISTING */}
          <div className='common-card mt-3 rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid className='mt-3'>
                <h4 className='fw-bold'>
                  <IoFilter size={'2rem'} className='me-2' />
                  Shops Listing Filters
                </h4>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return `Select Year`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      value={selectedOption}
                      disabled={newParam.has('start_date') && newParam.has('end_date')}
                      onChange={(event) => {
                        setPage(0), setClickedRows({}), setSelectedOption(event.target.value)
                      }}
                    >
                      <MenuItem value='weekToDate'>Week to Date</MenuItem>
                      <MenuItem value='monthToDate'>Month to Date</MenuItem>
                      <MenuItem value='yearToDate' disabled={selectedYear === 'currentYear'}>
                        Year to Date
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('start_date')
                          ? new Date(newParam.get('start_date')).toISOString().split('T')[0]
                          : newParam.get('start_date')
                      }
                      onChange={(e) => onChangeFilter('start_date', e.target.value)}
                      max={getMaxDateTwoDaysBefore()}
                      disabled={false}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('end_date')
                          ? new Date(newParam.get('end_date')).toISOString().split('T')[0]
                          : newParam.get('end_date')
                      }
                      disabled={!newParam.get('start_date')}
                      onChange={(e) => onChangeFilter('end_date', e.target.value)}
                      min={new Date(newParam.get('start_date')).toISOString().split('T')[0]}
                      max={getMaxDateTwoDaysBefore()}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Shop'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Search Shop'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('search_shop') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => {
                          onChangeRouter('search_shop', e?.target?.value.trim()), setPage(0)
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      multiple
                      displayEmpty
                      IconComponent={() => null}
                      value={multipleShopNames}
                      onChange={(e) => {
                        const value = e.target.value
                        if (value.includes('all')) {
                          setPage(0)
                          setMultipleShopNames([])
                        } else {
                          setPage(0)
                          setMultipleShopNames(value)
                        }
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Select Multiple Shop</span>
                        }
                        const displayCount = 1
                        const hiddenCount = selected.length - displayCount
                        return (
                          <Stack gap={1} direction='row' flexWrap='wrap'>
                            {selected.slice(0, displayCount).map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() =>
                                  setMultipleShopNames(multipleShopNames.filter((item) => item !== value))
                                }
                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                              />
                            ))}
                            {hiddenCount > 0 && <Chip label={`+${hiddenCount} more`} />}
                          </Stack>
                        )
                      }}
                    >
                      <MenuItem value='all'>All Country</MenuItem>
                      {shop.map((name) => (
                        <MenuItem key={name} value={name} sx={{ justifyContent: 'space-between' }}>
                          {name}
                          {multipleShopNames.includes(name) ? <CheckIcon color='info' /> : null}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={selectedShipmentCountry}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Shipment Country</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setPage(0), setClickedRows({}), setSelectedShipmentCountry(e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Country</MenuItem>
                      {shipment.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('commerce_type_shop') || ''}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Commerce Type'
                        }
                        const menuItems = [
                          { value: 'ecomm', label: 'Ecomm' },
                          { value: 'false', label: 'Non Ecomm' }
                        ]

                        const selectedItem = menuItems.find((item) => item.value === selected)
                        return selectedItem ? selectedItem.label : 'Commerce Type'
                      }}
                      onChange={(e) => {
                        onChangeRouter('commerce_type_shop', e?.target?.value.trim()), setPage(0)
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value='ecomm'>Ecomm</MenuItem>
                      <MenuItem value='false'>Non Ecomm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => ExportData(shops.period_data, 'shops')}
                    style={{
                      backgroundColor: '#424242',
                      color: 'white',
                      textTransform: 'none',
                      minWidth: 150,
                      fontSize: 16
                    }}
                  >
                    <BiImport className='me-2' size='1.2rem' />
                    Export Data{' '}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      handleResetFilters('SHOPS')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <section className='dashboard-graph mt-4 mb-2'>
            <div className='row gy-3'>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex  flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2 mb-sm-0 mb-lg-2'>Shops Listing</h5>
                  </div>
                  <div className='common-card-content'>
                    <Grid item xs={12} lg={6} md={6} sm={6}>
                      <CommonTableComponent
                        shops={shops}
                        selectedYear={selectedYear}
                        lastMonth={lastMonth}
                        currentMonth={currentMonth}
                        lastYear={lastYear}
                        currentYear={currentYear}
                        loading={loading}
                        rowsPerPage={10}
                        page={page}
                        handleChangePage={handleChangePage}
                        fetchDataPaticularShop={fetchDataPaticularShop}
                        shopCountryData={shopCountryData}
                        shopCountryDataLoading={shopCountryDataLoading}
                        setClickedRows={setClickedRows}
                        clickedRows={clickedRows}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2  mb-sm-0 mb-lg-2'>Shops Graph Analysis</h5>
                  </div>
                  <div className='common-card-content'>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      md={6}
                      sm={6}
                      sx={{
                        marginTop: '20px',
                        marginBottom: '20px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {isLoadingMonthShop || isLoadingWeekShop || isLoadingYearShop || isLoadingCustomShop ? (
                        ''
                      ) : (
                        <IconButton
                          sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
                          onClick={handleOpenSecond}
                        >
                          {' '}
                          <ZoomInMapIcon />
                        </IconButton>
                      )}
                      {/* Chart Component */}
                      {isLoadingMonthShop || isLoadingWeekShop || isLoadingYearShop || isLoadingCustomShop ? (
                        <CommonLoader title={'Loading Shops Graph Analysis'} />
                      ) : pDataShop.length && uDataShop.length && xLabelsShop.length ? (
                        <>
                          <BarChart
                            width={650}
                            height={700}
                            // margin={{ bottom: 150 }}
                            series={[
                              {
                                data: pDataShop,
                                label: selectedYear === 'lastYear' ? 'LastYear' : 'LastMonth',
                                id: 'pvId',
                                color: 'rgb(60 75 97)'
                              },
                              {
                                data: uDataShop,
                                label: selectedYear === 'lastYear' ? 'CurrentYear' : 'CurrentMonth',
                                id: 'uvId',
                                color: '#ADD8E6'
                              }
                            ]}
                            xAxis={[{ data: xLabelsShop, scaleType: 'band', ticks: xLabelsShop.length }]}
                            sx={{
                              '& .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px'
                              }
                            }}
                          />
                        </>
                      ) : (
                        <div>No data to display for this.</div>
                      )}
                      <MuiModal
                        open={openSecond}
                        onClose={handleCloseSecond}
                        aria-labelledby='modal-title'
                        aria-describedby='modal-description'
                      >
                        <Box sx={{ ...modalStyle }}>
                          <IconButton
                            sx={{
                              position: 'fixed',
                              top: '10px',
                              right: '10px',
                              zIndex: 1000
                            }}
                            onClick={handleCloseSecond}
                          >
                            <Close />
                          </IconButton>
                          <BarChart
                            width={window.innerWidth - 100}
                            height={window.innerHeight}
                            margin={{ bottom: 150 }}
                            series={[
                              {
                                data: pDataShop,
                                color: 'rgb(60 75 97)'
                              },
                              {
                                data: uDataShop,
                                color: '#ADD8E6'
                              }
                            ]}
                            xAxis={[
                              {
                                data: xLabelsShop,
                                scaleType: 'band'
                              }
                            ]}
                            sx={{
                              '& .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel': {
                                // transform: `${xLabelsShop.length <= 10 ? 'translateY(30px) rotate(-75deg)' : xLabelsShop.length > 30 ? 'translateY(80px) rotate(-75deg)' : 'translateY(70px) rotate(-75deg)'}`,
                                // textAnchor: 'end',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px'
                              }
                            }}
                          ></BarChart>
                        </Box>
                      </MuiModal>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* FILTER SKU LISTING */}
          <div className='common-card mt-3 rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid className='mt-3'>
                <h4 className='fw-bold'>
                  <IoFilter size={'2rem'} className='me-2' />
                  Sku Listing Filters
                </h4>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return `Select Year`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      value={skuSelectedOption}
                      disabled={newParam.has('sku_start_date') && newParam.has('sku_end_date')}
                      onChange={(event) => {
                        setSkuPage(0), setSkuSelectedOption(event.target.value)
                      }}
                    >
                      <MenuItem value='weekToDate'>Week to Date</MenuItem>
                      <MenuItem value='monthToDate'>Month to Date</MenuItem>
                      <MenuItem value='yearToDate' disabled={selectedYear === 'currentYear'}>
                        Year to Date
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('sku_start_date')
                          ? new Date(newParam.get('sku_start_date')).toISOString().split('T')[0]
                          : newParam.get('sku_start_date')
                      }
                      onChange={(e) => onChangeFilter('sku_start_date', e.target.value)}
                      max={getMaxDateTwoDaysBefore()}
                      disabled={false}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('sku_end_date')
                          ? new Date(newParam.get('sku_end_date')).toISOString().split('T')[0]
                          : newParam.get('sku_end_date')
                      }
                      disabled={!newParam.get('sku_start_date')}
                      onChange={(e) => onChangeFilter('sku_end_date', e.target.value)}
                      min={new Date(newParam.get('sku_start_date')).toISOString().split('T')[0]}
                      max={getMaxDateTwoDaysBefore()}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Sku'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Search Sku'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('search_sku') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => {
                          onChangeRouter('search_sku', e?.target?.value.trim()), setSkuPage(0)
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={selectedShop}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>All Shop</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setSkuPage(0), setSelectedShop(e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Shop</MenuItem>
                      {shop.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={selectedShipmentCountrySku}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Shipment Country</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setSkuPage(0), setSelectedShipmentCountrySku(e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Country</MenuItem>
                      {shipment.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={selectedPercent}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Range</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(event) => {
                        setSkuPage(0), setSelectedPercent(event.target.value)
                      }}
                    >
                      <MenuItem value=''>All</MenuItem>
                      <MenuItem value='mostDecreased'>Most Decreased</MenuItem>
                      <MenuItem value='mostIncreased'>Most Increased</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('warehouse')}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>All Warehouse</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setSkuPage(0), onChangeRouter('warehouse', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Warehouse</MenuItem>
                      {wareHouse.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={selectedSkuFilter === 'All' ? 'Warehouse Discontinued Products' : selectedSkuFilter}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => {
                        setSelectedSkuFilter(e.target.value)
                      }}
                      IconComponent={() => null}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return `SKU Discontinued Filter`
                        }
                        return selected
                      }}
                    >
                      <MenuItem value='All'>All</MenuItem>
                      {wareHouse.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('commerce_type_sku')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Commerce Type'
                        }
                        const menuItems = [
                          { value: 'ecomm', label: 'Ecomm' },
                          { value: 'false', label: 'Non Ecomm' }
                        ]

                        const selectedItem = menuItems.find((item) => item.value === selected)
                        return selectedItem ? selectedItem.label : 'Commerce Type'
                      }}
                      onChange={(e) => {
                        setSkuPage(0), onChangeRouter('commerce_type_sku', e.target.value)
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value='ecomm'>Ecomm</MenuItem>
                      <MenuItem value='false'>Non Ecomm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => ExportData(shopsSku.period_data, 'sku_shops')}
                    style={{
                      backgroundColor: '#424242',
                      color: 'white',
                      textTransform: 'none',
                      minWidth: 150,
                      fontSize: 16
                    }}
                  >
                    <BiImport className='me-2' size='1.2rem' />
                    Export Data{' '}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      handleResetFilters('SKU')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <section className='dashboard-graph mt-4 mb-2'>
            <div className='row gy-3'>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex  flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2 mb-sm-0 mb-lg-2'>Sku Listing</h5>
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='fw-semibold me-2' style={{ fontSize: 20 }}>
                        Sort By :
                      </span>
                      <Grid item xs={12} sm={6} md={4} lg={1}>
                        <FormControl
                          fullWidth
                          sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                          size='small'
                          className='border-none'
                        >
                          <Select
                            displayEmpty
                            InputLabelProps={{ shrink: false }}
                            IconComponent={() => null}
                            value={newParam.get('sort_by') || 'SKU'}
                            onChange={(event) => {
                              setSkuPage(0), onChangeRouter('sort_by', event.target.value)
                            }}
                          >
                            <MenuItem value='SKU'>SKU</MenuItem>
                            <MenuItem value='Design'>Design</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </div>
                  </div>
                  <div className='common-card-content'>
                    <Grid item xs={12} lg={6} md={6} sm={6}>
                      <CommonTableSku
                        rowsPerPage={20}
                        page={skupage}
                        loading={skuLoading}
                        data={filteredSkus}
                        handleChangePage={handleSkuChangePage}
                        lastMonth={lastMonth}
                        sortBy={newParam.get('sort_by') || 'SKU'}
                        lastYear={lastYear}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                        selectedYear={selectedYear}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 px-xs-0'>
                <div className='common-card rounded-2 h-100'>
                  <div className='common-card-title d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between align-items-center'>
                    <h5 className='mb-2  mb-sm-0 mb-lg-2'>Country Graph Analysis</h5>
                  </div>
                  <div className='common-card-content h-100'>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      md={6}
                      sm={6}
                      sx={{
                        marginTop: '20px',
                        marginBottom: '20px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {isLoadingMonthCountry ||
                      isLoadingWeekCountry ||
                      isLoadingYearCountry ||
                      isLoadingCustomCountry ? (
                        ''
                      ) : (
                        <IconButton
                          sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
                          onClick={handleOpen}
                        >
                          <ZoomInMapIcon />
                        </IconButton>
                      )}
                      {/* Chart Component */}
                      {isLoadingMonthCountry ||
                      isLoadingWeekCountry ||
                      isLoadingYearCountry ||
                      isLoadingCustomCountry ? (
                        <CommonLoader title={'Loading Sku Graph Analysis'} />
                      ) : pData.length && uData.length && xLabels.length && filteredSkus?.length ? (
                        <>
                          <BarChart
                            width={700}
                            height={700}
                            margin={{ top: 150 }}
                            series={[
                              {
                                data: pData,
                                label: selectedYear === 'lastYear' ? 'LastYear' : 'LastMonth',
                                id: 'pvId',
                                color: 'rgb(60 75 97)'
                              },
                              {
                                data: uData,
                                label: selectedYear === 'lastYear' ? 'CurrentYear' : 'CurrentMonth',
                                id: 'uvId',
                                color: '#ADD8E6'
                              }
                            ]}
                            xAxis={[{ data: xLabels, scaleType: 'band', ticks: xLabelsShop.length }]}
                            sx={{
                              '& .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel': {
                                // transform: 'translateY(50px) rotate(-45deg) !important',
                                // textAnchor: 'end',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px'
                              }
                            }}
                          />
                        </>
                      ) : (
                        <div className='mt-5'>No data to display for this.</div>
                      )}
                      <MuiModal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='modal-title'
                        aria-describedby='modal-description'
                      >
                        <Box sx={{ ...modalStyle }}>
                          <IconButton
                            sx={{
                              position: 'fixed',
                              top: '10px',
                              right: '10px',
                              zIndex: 1000
                            }}
                            onClick={handleClose}
                          >
                            <Close />
                          </IconButton>
                          <BarChart
                            width={window.innerWidth - 100}
                            height={window.innerHeight}
                            margin={{ bottom: 150 }}
                            series={[
                              {
                                data: pData,
                                color: 'rgb(60 75 97)'
                              },
                              {
                                data: uData,
                                color: '#ADD8E6'
                              }
                            ]}
                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                            sx={{
                              '& .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px'
                              }
                            }}
                          />
                        </Box>
                      </MuiModal>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default AnalyticsIndex

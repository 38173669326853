import axios from 'axios'
import authHeader from './auth-header'

const createJob = (CompanyId, UserName, UserId, Url) => {
  const input = {
    CompanyId,
    UserName,
    UserId,
    Url
  }
  return axios.post(
    `${process.env.REACT_APP_API}/job`,
    input,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getHistoryOfBulkImp = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/history`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getOdooPurchaseOrder = () => {
  return axios.get(
    `${process.env.REACT_APP_API_HANGFIRE}/Jobs/odoopurchaseorder`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}
const getOdooProduct = () => {
  return axios.get(
    `${process.env.REACT_APP_API_HANGFIRE}/Jobs/odooproducts`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const migrateWareHouseData = () => {
  return axios.get(
    `${process.env.REACT_APP_API_HANGFIRE}/Jobs/odooStockAlertsapi`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}


const migrateSalesData = () => {
  return axios.get(
    `${process.env.REACT_APP_API_HANGFIRE}/Jobs/odoosalesapi`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const hangfireService = {
  createJob,
  getHistoryOfBulkImp,
  getOdooPurchaseOrder,
  getOdooProduct,
  migrateWareHouseData,
  migrateSalesData
}

export default hangfireService

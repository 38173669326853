import React from 'react'
import { CNavItem, CNavTitle } from '@coreui/react'
import { MdOutlineDashboard, MdManageAccounts, MdAddAlert } from 'react-icons/md'
import { RiProductHuntLine, RiRefundFill } from 'react-icons/ri'
import { BsCardChecklist } from 'react-icons/bs'
import { IoPricetagsOutline } from 'react-icons/io5'
import { AiOutlineStock } from 'react-icons/ai'
import { BsBorderStyle } from "react-icons/bs"
import Inventory2Icon from '@mui/icons-material/Inventory2'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PaymentsIcon from '@mui/icons-material/Payments'
import { RiAdvertisementLine } from "react-icons/ri"
import SummarizeIcon from '@mui/icons-material/Summarize'
import { MdOutlineAssignmentReturn } from "react-icons/md"

const _nav = [

  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <MdOutlineDashboard className='nav-icon' />,
    role: 5,
    pricing: 3,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'Manage',
    role: 2,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'Manage',
    role: 2,
    pricing: 1,
    userType: 2
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <RiProductHuntLine className='nav-icon' />,
    role: 2,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <RiProductHuntLine className='nav-icon' />,
    role: 2,
    pricing: 1,
    userType: 2
  },
  {
    component: CNavItem,
    name: 'Purchase Order',
    to: '/purchaseorders',
    icon: <BsCardChecklist className='nav-icon' />,
    role: 2,
    pricing: 2,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Purchase Order',
    to: '/purchaseorders',
    icon: <BsCardChecklist className='nav-icon' />,
    role: 2,
    pricing: 2,
    userType: 2
  },
  {
    component: CNavItem,
    name: 'Projects',
    to: '/projects',
    icon: <IoPricetagsOutline className='nav-icon' />,
    role: 2,
    pricing: 2,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'Manage Retail Shops',
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders',
    icon: <PaymentsIcon className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'Reports',
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Sales Dashboard',
    to: '/salesdashboard',
    icon: <Inventory2Icon className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Analytics',
    to: '/analytics',
    icon: <AssessmentIcon className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Trends / Alerts',
    to: '/trends',
    icon: <MdAddAlert className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Advertisement',
    to: '/advertisement',
    icon: <RiAdvertisementLine className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },


  // {
  //   component: CNavItem,
  //   name: 'Stock Overview',
  //   to: '/inventory_data',
  //   icon: <BsCardChecklist className='nav-icon' />,
  //   role: 5,
  //   pricing: 1,
  //   userType:1
  // },
  {
    component: CNavItem,
    name: 'Refund & Commerical',
    to: '/refund/list',
    icon: <RiRefundFill className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },

  {
    component: CNavTitle,
    name: 'Retail Shop',
    role: 1,
    pricing: 1,
    userType: 3,
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders',
    icon: <BsBorderStyle className='nav-icon' />,
    role: 2,
    pricing: 1,
    userType: 3
  },
  {
    component: CNavItem,
    name: 'Stock Overview',
    to: '/inventory_data',
    icon: <AiOutlineStock className='nav-icon' />,
    role: 2,
    pricing: 1,
    userType: 3
  },

  // User For PomUserType === 4 Functionality 
  {
    component: CNavTitle,
    name: 'Manage Retail Shops',
    role: 4,
    pricing: 1,
    userType: 5
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders',
    icon: <BsCardChecklist className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 5
  },
  {
    component: CNavItem,
    name: 'Refund & Commerical',
    to: '/refund/list',
    icon: <RiRefundFill className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 5
  },
  {
    component: CNavItem,
    name: 'Sales Report',
    to: '/salesreport',
    icon: <SummarizeIcon className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'H&M Returns',
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavItem,
    name: 'Returns',
    to: '/returns',
    icon: <MdOutlineAssignmentReturn className='nav-icon' />,
    role: 4,
    pricing: 1,
    userType: 1
  },
  {
    component: CNavTitle,
    name: 'Settings',
    role: 2,
    pricing: 1,
    userType: 4
  },
  {
    component: CNavItem,
    name: 'Account',
    to: '/account',
    icon: <MdManageAccounts className='nav-icon' />,
    role: 2,
    pricing: 1,
    userType: 4
  },

]

export default _nav

import React from 'react'
import { useSkuTable } from './useSkuTable'
import PropTypes from 'prop-types'
import BasicTable from 'components/uiElements/table/BasicTable'

const SkuTable = (props) => {

  const { columns, rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useSkuTable(
    props.Products,
    props.skuValue,
    props.vendors,
    props.setSkuValue,
    props.vendorProducts,
    props.setVendorProducts,
    props.vendorsAllProducts,
    props.setVendorsAllProducts
  )
  return (
    <div className='p-5'>
      <div className='row'>
        <div className='col'>
          <h3>Added Products</h3>
        </div>
      </div>
      <BasicTable
        columns={columns}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        Nopagination={true}
      />
    </div>
  )
}

export default SkuTable

SkuTable.propTypes = {
  Products: PropTypes.array,
  skuValue: PropTypes.array,
  vendors: PropTypes.array,
  setSkuValue: PropTypes.func,
  vendorProducts: PropTypes.array,
  vendorsAllProducts: PropTypes.array,
  setVendorProducts: PropTypes.func,
  setVendorsAllProducts: PropTypes.func
}

import { IconButton, Tooltip, Grid, Button } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import PropTypes from 'prop-types'
import { CCol, CRow } from '@coreui/react'
// import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import { CFormLabel, CFormInput } from '@coreui/react'
// import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { UserContext } from 'context/AuthContext/UserContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // backgroundColor:"transparent" ,
  // boxShadow:"none",
  width: 400,
  bgcolor: 'background.paper',
  color: 'white',
  p: 4,
  borderRadius: '2px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Adjust background color fade
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' // Add modal shadow
}
export const useSkuTable = (
  products,
  skuValue,
  vendors,
  setSkuValue,
  vendorProducts,
  setVendorProducts,
  vendorsAllProducts,
  setVendorsAllProducts
) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState(null)
  const { userData } = useContext(UserContext)

  const columns = [
    { id: 'image', label: 'Image', minWidth: 100 },
    { id: 'sku', label: 'Sku', minWidth: 100 },
    { id: 'vendor', label: 'Vendor\u00a0Name', minWidth: 100 },
    { id: 'quantity', label: 'Quantity', minWidth: 100 },
    { id: 'action', label: 'Action', minWidth: 100 }
  ]

  // const updatedColumns = columns.filter((column) => {
  //   if (userData?.user?.pomUserType !== 1) {
  //     return column.id !== 'action'
  //   }
  //   return true
  // })

  function createData(image, sku, vendor, quantity, action) {
    return { image, sku, vendor, quantity, action }
  }
  const inputRef = useRef(null)
  const [editQuantity, setEditQuantity] = useState({
    quantity: ''
  })
  const handleOpen = (index) => {
    setSelectedProduct(index)
    setEditQuantity({ quantity: skuValue[index].qty.split('.')[0] })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedProduct(null)
    setEditQuantity({ quantity: '' })
  }

  const removeProductHandle = (index) => {
    let arr = [...skuValue]
    const removedProduct = arr.splice(index, 1)[0]
    setSkuValue(arr)

    const filteredProduct = vendorProducts.find((product) => product.id === removedProduct.poProduct.id)

    if (filteredProduct) {
      let updatedProducts = [...vendorsAllProducts]

      const insertIndex = vendorsAllProducts.findIndex((product) => product.id === filteredProduct.id)

      if (insertIndex === -1) {
        updatedProducts.push(filteredProduct)
      } else {
        updatedProducts[insertIndex] = filteredProduct
      }

      setVendorProducts(updatedProducts)
      setVendorsAllProducts(updatedProducts)
    }
  }

  const saveEditQuantity = () => {
    if (selectedProduct !== null) {
      let arr = [...skuValue]
      arr[selectedProduct] = { ...skuValue[selectedProduct], qty: editQuantity?.quantity }

      setSkuValue(arr)
      handleClose()
    }
  }
  const handlechange = (e) => {
    let name = e.target.name
    let value = e.target.value
    setEditQuantity((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  const Action = (props) => {
    return (
      <>
        <CRow>
          <CCol>
            <Tooltip title={'Edit Quantity'}>
              <div className='col-md-auto'>
                <IconButton aria-label='view' color='success' onClick={() => handleOpen(props.product)}>
                  <AiFillEdit fontSize={25} color='blue' />
                </IconButton>
              </div>
            </Tooltip>
          </CCol>
          <CCol>
            <Tooltip title={'Remove Sku'}>
              <div className='col-md-auto'>
                <IconButton aria-label='view' color='success' onClick={() => removeProductHandle(props.product)}>
                  <AiFillDelete fontSize={25} color='red' />
                </IconButton>
              </div>
            </Tooltip>
          </CCol>
        </CRow>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          trapFocus={false}
        >
          <Box sx={style}>
            <Grid item sm={10}>
              <CFormLabel style={{ color: 'black' }}>Quantity</CFormLabel>
              <CFormInput
                inputRef={inputRef}
                type='number'
                name='quantity'
                value={editQuantity.quantity}
                onChange={(e) => handlechange(e)}
                autoFocus={true}
              />
            </Grid>
            <Grid item sm={12} m={2} className='m-10'>
              <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                <Grid item>
                  <Button variant='outlined' onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='outlined' color='success' onClick={saveEditQuantity}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    )
  }
  Action.propTypes = {
    product: PropTypes.string,
    pr: PropTypes.object
  }

  const rows = skuValue.map((product, index) =>
    createData(
      <img src={product.poProduct.images[0]} alt='img' height={50} width={50} />,
      product.poProduct.sku,
      product.supplierName,
      product.qty,
      (userData?.user?.pomUserType == 1 || (userData?.user?.pomUserType !== 1 && !product?.supplierName)) && (
        <Action product={index} pr={product} />
      )
    )
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const toggleDrawer = (e) => {
    e.preventDefault()
    setFilterDrawer(!filterDrawer)
  }

  return {
    columns,
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    toggleDrawer
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { flash } from 'react-animations'
import './Sidebox.css'

const Bounce = styled.div`
  animation: 3s ${keyframes`${flash}`} infinite;
`

const SideBox = (props) => {
  const items = props.items.map((item, index) => {
    return (
      <li key={index}>
        {item.title}
        <p>{item.detail}</p>
      </li>
    )
  })

  return (
    <div className={`w-100 mt-3 mb-3 h-75 d-inline-block ${props.className}`}>
      <div className='boxed-widget summary'>
        <h3 className='text-center'>
          <Bounce>
            <img
              className='mr-1 mb-1'
              src={require('assets/svgs/shared/lamp.svg').default}
              style={{ width: 32, height: 32 }}
              alt='infobox'
            />{' '}
            &nbsp;
            {props.title}
          </Bounce>
        </h3>

        <ul>{items}</ul>

        {props.footer !== undefined && <>{props.footer}</>}
      </div>
    </div>
  )
}

SideBox.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  footer: PropTypes.object
}

export default SideBox

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button } from '@mui/material'
import { PDFExport } from '@progress/kendo-react-pdf'
import './purchaseorder.css'
import PoInvoiceTemplate from './PoInvoiceTemplate'
import { UserContext } from 'context/AuthContext/UserContext'


const PurchaseorderTemplate = (props) => {
    const { showToast } = useContext(UserContext)
    const { selectedPdfType, purchaseOrderDetail, rows } = props
    const pdfExportComponent = React.useRef(null)

    return (
        <>
            <PDFExport
                scale={0.7}
                margin={{ top: 20, left: 5, right: 5, bottom: 40 }}
                keepTogether='p'
                paperSize='A4'
                repeatHeaders={true}
                ref={pdfExportComponent}
                fileName={purchaseOrderDetail?.poNumber}
            >
                <PoInvoiceTemplate
                    selectedPdfType={selectedPdfType}
                    purchaseOrderDetail={purchaseOrderDetail}
                    rows={rows}
                />
            </PDFExport>
            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                <Button variant='outlined' onClick={() => { showToast('Please wait while we fetching the pdf ...', false), pdfExportComponent.current.save() }}>
                    Download
                </Button>
            </Grid>
        </>
    )
}

PurchaseorderTemplate.propTypes = {
    selectedPdfType: PropTypes.string.isRequired,
    purchaseOrderDetail: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PurchaseorderTemplate
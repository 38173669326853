const ImageFiller = 'https://innoage.blob.core.windows.net/images/assets/placeholder_img.png'
const DesignedWithFooter =
  'https://res.cloudinary.com/arunkumars/image/upload/v1647012922/assets/designedwith_oxmq2r.png'
const DummyUserAvtar = 'https://res.cloudinary.com/arunkumars/image/upload/v1647353064/assets/user_uizbsf.png'
const Security = 'https://innoageimages.blob.core.windows.net/images/assets/security.svg'
const Loading = 'https://innoageimages.blob.core.windows.net/images/assets/loading.svg'
const Trash = 'https://innoageimages.blob.core.windows.net/images/assets/trash.svg'
const UserChatImage = 'https://innoage.blob.core.windows.net/images/assets/buyer-avtar.webp'
const Messaging = 'https://innoage.blob.core.windows.net/images/assets/personal_text.svg'
const InnoAgeLogoWithBG = 'https://innoage.blob.core.windows.net/images/assets/color_logo_bg.jpg'
const UserLoginSideImg = 'https://innoage.blob.core.windows.net/pom/user-img.jpg'
const ErrorOccured = 'https://innoage.blob.core.windows.net/images/assets/error.svg'
const NoData = 'https://innoage.blob.core.windows.net/images/assets/nodata.svg'

const Images = {
  ImageFiller,
  DesignedWithFooter,
  DummyUserAvtar,
  Security,
  Loading,
  Trash,
  UserChatImage,
  Messaging,
  InnoAgeLogoWithBG,
  UserLoginSideImg,
  ErrorOccured,
  NoData
}

export default Images

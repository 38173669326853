import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, Skeleton, Grid } from '@mui/material'
import { useRefundListHooks } from './useRefundListHooks'
import Loader from 'components/common/Loader'
import RefundTable from './refundTable'

import Seo from 'components/common/seo'

const SkeletonRow = ({ cols }) => (
  <TableRow>
    {Array.from({ length: cols }, (_, index) => (
      <TableCell key={index}>
        <Skeleton variant='text' />
      </TableCell>
    ))}
  </TableRow>
)

SkeletonRow.propTypes = {
  cols: PropTypes.number.isRequired
}

const HnmReturns = () => {
  const { isLoading, openStatus, handleOpenDialogBox, returnList, status, handleChange, updateStatus,orderNumber, setOrderNumber } =
    useRefundListHooks()
  return (
    <>
      <Seo
        title='H&M Returns'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />

      <section className='page-spacing'>
        <div className='container-fluid'>
          <div className='common-card rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8} lg={9}>
                  <h3 className='fw-bold advertisement-report'>H&M Returns</h3>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='row mt-4'>
                <div className='col-md-12 h-100'>
                  <div className='common-card rounded-3'>
                    <div className='common-card-title'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h5 className='fw-bold '>Returns Listing</h5>
                      </div>
                      <hr
                        style={{
                          color: '#000000',
                          backgroundColor: '#000000',
                          height: 0.5,
                          borderColor: '#000000'
                        }}
                      />
                    </div>
                    <div className='common-card-content border-0 mt-0 mb-0'>
                      <RefundTable
                        returnList={returnList?.data}
                        openStatus={openStatus}
                        handleOpenDialogBox={handleOpenDialogBox}
                        status={status}
                        handleChange={handleChange}
                        updateStatus={updateStatus}
                        orderNumber={orderNumber}
                         setOrderNumber={setOrderNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default HnmReturns

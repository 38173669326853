import axios from 'axios'
import authHeader from 'shared/services/auth-header'


const apiClientHnm = axios.create({
  baseURL: process.env.REACT_APP_HNM_API,
  timeout: 360000,
})

apiClientHnm.interceptors.request.use(
  async config => {
    const headers = await authHeader()
    config.headers = {
      ...config.headers,
      ...headers,
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

export default apiClientHnm
import { useMutation, useQuery } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import React,{ useContext, useState } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'

export const useRefundListHooks = () => {
  const newParam = new URLSearchParams(location.search)
  const [skupage, setSkuPage] = useState(0)
  const [skurowsPerPage, setSkuRowsPerPage] = useState(50)
  // const [returnList, setReturnList] = useState()
  const [openStatus, setOpenStatus] = useState(false)
  const [status, setStatus] = React.useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const { showToast } = useContext(UserContext)

  const handleChange = (event) => {
    setStatus(event.target.value)
  }
  const handleSkuChangePage = (event, newPage) => {
    setSkuPage(newPage)
  }
  const handleSkuChangeRowsPerPage = (event) => {
    setSkuRowsPerPage(parseInt(event.target.value, 10))
    setSkuPage(0)
  }

  const fetchHnmReturnList = async () => {
    try {
      const response = await purchaseOrderService.getHnmReturnList()
      return response?.data
    } catch (error) {
      throw error
    }
  }
  const handleOpenDialogBox=async()=>{
    setOpenStatus(!openStatus)
  }
  const updateStatus=async(orderNumber,status)=>{
    const finalPayload = {
      orderNumber: orderNumber,
      status:status,
      date:new Date()
    }

    updateStatusMutation.mutate(finalPayload)
  }

  const updateStatusMutation = useMutation(
    async (payload) => {
      const apiRes = await purchaseOrderService.updateOrderReturnStatus(payload)
      return apiRes.data
    },
    {
      onSuccess: (value) => {
        setOpenStatus(false)
        setStatus('')
        showToast(value?.message, true)
      },
      onError: () => {
        showToast("Something went wrong!")

      }
    }
  )

  const {
    refetch,
    data: returnList,
    isLoading: isLoading
  } = useQuery('refundlist', () => fetchHnmReturnList(), {
    refetchOnWindowFocus: false,
    onSuccess: () => {},
    onError: (error) => {
      console.log(error)
    }
    // enabled: param.projectId ? true : false
  })
  return {
    isLoading,
    newParam,
    skurowsPerPage,
    skupage,
    handleSkuChangePage,
    handleSkuChangeRowsPerPage,
    returnList,
    refetch,
    openStatus, handleOpenDialogBox,
    status,
    handleChange,
    updateStatus,
    orderNumber, setOrderNumber
  }
}

import React, { } from 'react'
import { TableRow, TableCell, Skeleton,  } from '@mui/material'
import PropTypes from 'prop-types'

const SkeletonRow = ({ cols }) => (
    <TableRow>
        {Array.from({ length: cols }, (_, index) => (
            <TableCell key={index}>
                <Skeleton variant='text' />
            </TableCell>
        ))}
    </TableRow>
)
SkeletonRow.propTypes = {
    cols: PropTypes.number.isRequired
}

export default SkeletonRow
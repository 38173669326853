import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { Grid, Paper } from '@mui/material'
import PropTypes from 'prop-types'
import { FaDownload, FaEdit } from "react-icons/fa"
import { Link } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3c4b64',  // Blue background for headers
        color: theme.palette.common.white,  // White color for text
        fontWeight: 'bold',
        padding: '12px 16px',
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '10px 16px',
        textAlign: 'center',
        backgroundColor: theme.palette.common.white,  // White background for all table body rows
    },
}))

const GrayHeaderCell = styled(TableCell)(({ }) => ({
    backgroundColor: '#f3f3f3',  // Gray background for filename-only headers
    color: '#2a2d39',
    fontWeight: 'bold',
    padding: '12px 16px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.selected,  // Hover effect
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const DocumentsTable = ({ projectDetail, handleOpen }) => {
    if (!projectDetail || !projectDetail.documents || projectDetail.documents.length === 0) {
        return (
            <Grid container direction="column" pt={4}>
                <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">
                                    No documents available.
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    No documents available.
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    }

    // Group documents by fileName
    const groupedDocuments = projectDetail.documents.reduce((acc, doc) => {
        if (!acc[doc.fileName]) {
            acc[doc.fileName] = []
        }
        acc[doc.fileName].push(...doc.url)  // Flatten URL array into file name group
        return acc
    }, {})

    return (
        <Grid container direction="column" pt={4}>
            <TableContainer sx={{ maxHeight: 600, marginBottom: 4 }} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">File Name</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groupedDocuments).map(([fileName, urls], index) => (
                            <React.Fragment key={`header-${fileName}-${index}`}>
                                <TableRow>
                                    {/* Set colSpan to cover all columns */}
                                    <GrayHeaderCell colSpan={2}>
                                        <span style={{ marginRight: '8px' }}>{index + 1}.</span>
                                        {fileName}
                                    </GrayHeaderCell>
                                </TableRow>
                                {urls.map((urlObj, urlIndex) => (
                                    <StyledTableRow key={`${fileName}-${urlIndex}`}>
                                        <StyledTableCell align="left">{urlObj.vendorName}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Link target='_blank' to={urlObj.link} style={{ textDecoration: 'none', color: '#2a2d39', alignItems: 'center', justifyContent: 'center' }}>
                                                <FaDownload size="1rem" color='#2a2d39' style={{ marginRight: '8px' }} />
                                            </Link>
                                            <FaEdit
                                                size="1rem"
                                                style={{ cursor: 'pointer', marginLeft: '12px', color: '#2a2d39' }}
                                                onClick={() => handleOpen({ vendorName: urlObj.vendorName, link: urlObj.link, fileName })}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

DocumentsTable.propTypes = {
    projectDetail: PropTypes.object,
    handleOpen: PropTypes.func,
}

export default DocumentsTable
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop
} from '@mui/material'
import PropTypes from 'prop-types'

const MessageDialogue = (props) => {
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      props.handleClose()
    }
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby={props.title}
        aria-describedby={props.title}
        disableEscapeKeyDown
        hideBackdrop
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText data-testid='dialog-content'>{props.content}</DialogContentText>
        </DialogContent>
        {!props.showButtons && (
          <DialogActions>
            <Button data-testid='cancel' onClick={props.handleClose}>Cancel</Button>
            <Button data-testid='ok' color={props.type} onClick={props.handleOK}>
              {props.okText ?? 'OK'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Backdrop>
  )
}

MessageDialogue.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  type: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOK: PropTypes.func,
  showButtons: PropTypes.bool,
  okText: PropTypes.string
}

export default MessageDialogue

import React from 'react'
import styled, { keyframes } from 'styled-components'
import { flash } from 'react-animations'
import PropTypes from 'prop-types'
import './InfoBox.css'
const Bounce = styled.div`
  animation: 3s ${keyframes`${flash}`} infinite;
`

const InfoBox = (props) => {
  return (
    <div className={`w-100 mt-2 mb-3 h-75 d-inline-block ${props.className}`}>
      <div className='boxed-widget summary text-center'>
        <h3>
          <Bounce>
            <img
              className='mr-2'
              src={require(`assets/svgs/tiles/${props.image}`)}
              style={{ width: 120, height: 120 }}
              alt=''
            />
          </Bounce>
          {props.title}
        </h3>

        <div className='row pt-1'>
          <div className='col card-heading-text text-center'>
            <h4>
              <strong>{props.body} </strong>
            </h4>
          </div>
        </div>
        <div className='row'>
          <div className='col card-footer-text text-center pb-2'>{props.footer}</div>
        </div>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  image: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string
}

export default InfoBox

import axios from 'axios'
import authHeader from 'shared/services/auth-header'
const odooClient = axios.create({
  baseURL: process.env.REACT_APP_ODOO_API,
  timeout: 30000
})

odooClient.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem('iauser'))
    let odooToken
    if (config.type) {
      odooToken = config.type
    } else {
      odooToken = user?.user?.pomUserType === 3 ? user.user.retailToken : user.user.admintoken
    }
    const headers = await authHeader('', '', odooToken)
    config.headers = {
      ...config.headers,
      ...headers
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default odooClient

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Button, Grid, Paper, Tooltip } from '@mui/material'
import Skeleton from '../skeleton/skeleton'
import { Link } from 'react-router-dom'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3c4b64',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({}) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const BasicTable = (props) => {
  const { columns, rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, isLoading, isProLoading } = props

  return (
    <Grid direction='column' pt={4}>
      <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow style={{ backgroundColor: 'white' }}>
              {columns.map((column) => (
                <StyledTableCell style={{ minWidth: column.minWidth }} key={column.id} align='center'>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {isProLoading ? (
            <Skeleton />
          ) : (
            <TableBody>
              {isLoading ? (
                <>
                  <StyledTableRow key='1'>
                    <Skeleton animation='wave' variant='rectangle' width='700px' />
                  </StyledTableRow>
                  <StyledTableRow key='2'>
                    <Skeleton animation='wave' variant='rectangle' width='700px' />
                  </StyledTableRow>
                </>
              ) : (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <StyledTableRow key={row.code}>
                      {columns.map((column) => {
                        const value = row[column?.id]
                        return column.id === 'name' ? (
                          <StyledTableCell align='center' key={column.id}>
                            <Tooltip
                              title={
                                <img
                                  src={
                                    value
                                      ? value
                                      : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                  }
                                  alt='Product'
                                  width='200px'
                                />
                              }
                              arrow
                              placement='right'
                              PopperProps={{
                                style: { marginLeft: '10px' }
                              }}
                            >
                              <img src={value} alt='Shape 1' width='60px' height='60px' />
                            </Tooltip>
                          </StyledTableCell>
                        ) : column.id === 'poNumber' ? (
                          <StyledTableCell align='center' key={column.id}>
                            <Link to={`/purchaseOrder/${value}`}>
                              <Button
                                component='a'
                                variant='contained'
                                className='purchaseOrderLink'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {value}
                              </Button>
                            </Link>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align='center' key={column.id}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {props.Nopagination && rows.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          style={{ marginTop: '10px' }}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid='table-pagination'
        />
      )}
    </Grid>
  )
}

BasicTable.propTypes = {
  rows: PropTypes.object,
  columns: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.object,
  handleChangeRowsPerPage: PropTypes.object,
  Nopagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProLoading: PropTypes.bool
}

export default BasicTable

import React from 'react'
import { Link } from 'react-router-dom'
import { CCol, CCard, CCardBody } from '@coreui/react'
import styled, { keyframes } from 'styled-components'
import { pulse } from 'react-animations'
import PropTypes from 'prop-types'

import './MenuTile.css'

const Bounce = styled.div`
  animation: 1s ${keyframes`${pulse}`} infinite;
`

const MenuTile = (props) => {
  return (
    <CCol xs='6' sm='6' md='4'>
      <CCard className='custom-card mb20'>
        <Link to={props.link} className='remove-link-props'>
          <CCardBody>
            <div className='row mb-10'>
              <div className='col fa-style text-center text-md-left'>
                <Bounce>
                  <img
                    className='mr-20 icon-hover'
                    src={require(`assets/svgs/tiles/${props.icon}`)}
                    style={{ width: 70, height: 70 }}
                    alt=''
                  />
                </Bounce>
              </div>
            </div>
            <div className='row'>
              <div className='col card-heading-text text-center text-md-left'>
                <h4>
                  <strong className='card-heading-text'>{props.body} </strong>
                </h4>
              </div>
            </div>
            <div className='row'>
              <div className='col card-footer-text text-center d-none d-md-block d-lg-block d-xl-block'>
                <p>{props.footer}</p>
              </div>
            </div>
            {props.children}
          </CCardBody>
        </Link>
      </CCard>
    </CCol>
  )
}

MenuTile.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  children: PropTypes.element
}

export default MenuTile

import { CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AiOutlinePlus } from 'react-icons/ai'
import { IconButton } from '@mui/material'
import '../purchaseOrder/create/Style.css'
import SearchableDropdown from 'pages/purchaseOrder/create/SearchableDropdown'

function AddPoProduct(props) {
  const [value, setValue] = useState({ poProduct: {}, qty: '', supplier: '' })
  const [notSelected, setNotSelected] = useState(false)
  const [qtyError, setQtyError] = useState(false)

  const handleAddProduct = () => {
    if (value.poProduct.images && value.poProduct.images.length > 0 && value.qty > 0) {
      setNotSelected(false)
      setQtyError(false)
      let arr = [...props.skuValue]

      arr.push(value)
      props.setSkuValue(arr)
      const filteredArr = props.vendorsAllProducts.filter((product) => product.id !== value.poProduct.id)
      props.setVendorsAllProducts(filteredArr)
      setValue({ poProduct: '', qty: '', supplier: '' })
    } else {
      if (!(value.poProduct.images && value.poProduct.images.length > 0)) {
        setNotSelected(true)
      }
      if (!(value.qty > 0)) {
        setQtyError(true)
      }
    }
  }
  return (
    <>
      <CForm onSubmit={handleAddProduct}>
        <CRow className='ms-4 mt-2'>
          <CCol sm={4}>
            <CFormLabel htmlFor='type' className='col-sm-4 col-form-label' data-testid='Supplier'>
              Product
            </CFormLabel>
            <SearchableDropdown
              options={props.vendorsAllProducts}
              measurements='measurements'
              images='images'
              label='sku'
              id='id'
              selectedVal={value.poProduct}
              onHandleChange={(val) => setValue({ ...value, poProduct: val, supplier: props.supplier })}
            />
            {notSelected && <p style={{ color: 'red' }}> Please Select A Sku</p>}
          </CCol>

          <CCol sm={4}>
            <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
              Quantity
            </CFormLabel>
            <CFormInput
              size='lg'
              type='number'
              id='Quantity'
              placeholder='Enter Qty'
              onChange={(e) => setValue({ ...value, qty: e.target.value })}
              value={value.qty}
            />
            {qtyError && <p style={{ color: 'red' }}> Please Enter Valid Qty</p>}
          </CCol>
          <CCol sm={4} className='mt-4'>
            <IconButton aria-label='view' color='success' className='mt-3 border rounded-0 ' onClick={handleAddProduct}>
              <AiOutlinePlus fontSize={26} color='black' />
            </IconButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  )
}

export default AddPoProduct

AddPoProduct.propTypes = {
  handleChange: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  vendorProducts: PropTypes.array,
  values: PropTypes.object,
  skuValue: PropTypes.object,
  supplier: PropTypes.string.isRequired,
  setSkuValue: PropTypes.func,
  setVendorProducts: PropTypes.func,
  vendorsAllProducts: PropTypes.array,
  setVendorsAllProducts: PropTypes.func
}

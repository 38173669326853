// /* eslint-disable */
import { useMutation, useQuery } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import productService from 'shared/services/products.service'
import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CalculateDate, sortAlphabeticOrder } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'

export const useAnalyticsHooks = () => {
  const [sales, setSales] = useState([])
  const [salesData, setSalesData] = useState([])
  const [error, setError] = useState(null)
  const [selectedOption, setSelectedOption] = useState('weekToDate')
  const [skuSelectedOption, setSkuSelectedOption] = useState('weekToDate')
  const [shipment, setShipment] = useState([])
  const [selectedYear, setSelectedYear] = useState('lastYear')
  const [shop, setShop] = useState([])
  const [wareHouse, setWareHouse] = useState([])
  const [selectedPercent, setSelectedPercent] = useState('')
  const [selectedShop, setSelectedShop] = useState('')
  const [selectedShipmentCountry, setSelectedShipmentCountry] = useState('')
  const [selectedShipmentCountrySku, setSelectedShipmentCountrySku] = useState('')
  const [monthToDateGraphShop, setMonthToDateGraphShop] = useState([])
  const [weekToDateGraphShop, setWeekToDateGraphShop] = useState([])
  const [yearToDateGraphShop, setYearToDateGraphShop] = useState([])
  const [monthToDateGraphCountry, setMonthToDateGraphCountry] = useState([])
  const [weekToDateGraphCountry, setWeekToDateGraphCountry] = useState([])
  const [yearToDateGraphCountry, setYearToDateGraphCountry] = useState([])
  const [currentYearMonthToDateGraphShop, setCurrentYearMonthToDateGraphShop] = useState([])
  const [currentYearWeekToDateGraphShop, setCurrentYearWeekToDateGraphShop] = useState([])
  const [currentYearMonthToDateGraphCountry, setCurrentYearMonthToDateGraphCountry] = useState([])
  const [currentYearWeekToDateGraphCountry, setCurrentYearWeekToDateGraphCountry] = useState([])
  const [isLoadingMonthShop, setIsLoadingMonthShop] = useState(false)
  const [isLoadingWeekShop, setIsLoadingWeekShop] = useState(false)
  const [isLoadingYearShop, setIsLoadingYearShop] = useState(false)
  const [isLoadingMonthCountry, setIsLoadingMonthCountry] = useState(false)
  const [isLoadingWeekCountry, setIsLoadingWeekCountry] = useState(false)
  const [isLoadingYearCountry, setIsLoadingYearCountry] = useState(false)
  const [isLoadingCustomShop, setIsLoadingCustomShop] = useState(false)
  const [isLoadingCustomCountry, setIsLoadingCustomCountry] = useState(false)
  const [customGraphShop, setCustomGraphShop] = useState([])
  const [customGraphCountry, setCustomGraphCountry] = useState([])
  const [customGraphShopCurrentYear, setCustomGraphShopCurrentYear] = useState([])
  const [customGraphCountryCurrentYear, setCustomGraphCountryCurrentYear] = useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)
  const { setLoading } = useContext(UserContext)
  const getMonthToDate = () => {
    const today = new Date()
    const startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))
    const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59))
    return { startDate: startDate, endDate: endDate }
  }
  const getYearToDate = () => {
    const today = new Date()
    const startDate = new Date(Date.UTC(today.getFullYear(), 0, 1))
    const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59))
    return { startDate: startDate, endDate: endDate }
  }
  const getWeekToDate = () => {
    const today = new Date()
    const dayOfWeek = today.getUTCDay()
    const startOfPreviousWeek = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek - 6)
    ) // Last Monday
    const endOfPreviousWeek = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek, 23, 59, 59)
    ) // Last Sunday
    return { startDate: startOfPreviousWeek, endDate: endOfPreviousWeek }
  }
  const [yearSelectStartDate, setYearSelectStartDate] = useState('')
  const [yearSelectEndDate, setYearSelectEndDate] = useState('')
  const [totalRevenueLoad, setTotalRevenueLoad] = useState(false)
  const [selectedSkuFilter, setSelectedSkuFilter] = useState('All')
  const [filteredSkus, setFilteredSkus] = useState([])
  const GetAllDiscontinuedProducts = async () => {
    try {
      const response = await productService.GetAllDiscontinuedProducts(selectedSkuFilter)
      const data = await response.data
      if (data === 'No Data Available') {
        setFilteredSkus([])
      }
      if (selectedSkuFilter === 'All') {
        setFilteredSkus(shopsSku.period_data)
      } else if (data.length) {
        const filterDiscontinuedSKu = await shopsSku.period_data.filter((skuItem) =>
          data.some((discontinuedItem) => discontinuedItem.sku === skuItem.sku)
        )
        setFilteredSkus(filterDiscontinuedSKu)
      }
    } catch (error) {
      setError(error?.message || 'Some Error Occurred, Please Try Again')
    } finally {
    }
  }

  const getSales = async (dateObj) => {
    setTotalRevenueLoad(true)
    try {
      const response = await purchaseOrderService.getSalesDataYears(JSON.stringify(dateObj))
      const data = await response.data
      setSales(data)
      setTotalRevenueLoad(false)
    } catch (error) {
      setError(error?.message || 'Some Error Occurred, Please Try Again')
    } finally {
      setTotalRevenueLoad(false)
    }
  }
  const { mutate: getSalesData, isLoading: isSalesLoading } = useMutation(
    async (variables) => {
      try {
        if (variables.type === 'month') {
          if (variables.filterType === 'shop') {
            setIsLoadingMonthShop(true)
          } else if (variables.filterType === 'country') {
            setIsLoadingMonthCountry(true)
          }
        } else if (variables.type === 'week') {
          if (variables.filterType === 'shop') {
            setIsLoadingWeekShop(true)
          } else if (variables.filterType === 'country') {
            setIsLoadingWeekCountry(true)
          }
        } else if (variables.type === 'year') {
          if (variables.filterType === 'shop') {
            setIsLoadingYearShop(true)
          } else if (variables.filterType === 'country') {
            setIsLoadingYearCountry(true)
          }
        } else if (variables.type === 'custom') {
          if (variables.filterType === 'shop') {
            setIsLoadingCustomShop(true)
          } else if (variables.filterType === 'country') {
            setIsLoadingCustomCountry(true)
          }
        }

        const response = await purchaseOrderService.getGraphData(
          variables.startDate,
          variables.endDate,
          variables.filterType,
          variables.year,
          variables.filterType === 'shop' ? variables.country : null,
          variables.filterType === 'country' ? variables.shop : null,
          variables.commerce_type
        )
        let apiRes
        if (variables.filterType === 'shop') {
          apiRes = await response.data
          if (newParam.get('search_shop')) {
            const filteredSalesCurrent = apiRes.currentYearSales.filter(
              (res) => res.shop.toLowerCase() === newParam.get('search_shop').toLowerCase()
            )
            const filteredSalesPrevious = apiRes.previousYearSales.filter(
              (res) => res.shop.toLowerCase() === newParam.get('search_shop').toLowerCase()
            )
            return { currentYearSales: filteredSalesCurrent, previousYearSales: filteredSalesPrevious }
          }
          return apiRes
        } else {
          apiRes = await response.data
          if (selectedShipmentCountrySku) {
            const filteredSalesCurrent = apiRes.currentYearSales.filter(
              (res) => res.shipment_country === selectedShipmentCountrySku
            )
            const filteredSalesPrevious = apiRes.previousYearSales.filter(
              (res) => res.shipment_country === selectedShipmentCountrySku
            )
            return { currentYearSales: filteredSalesCurrent, previousYearSales: filteredSalesPrevious }
          }
          return apiRes
        }
      } catch (error) {
        throw new Error(error || 'Some error occurred')
      }
    },
    {
      onSuccess: (data, variables) => {
        setSalesData(data)
        // Clear loading state for the completed API call
        if (variables.year === 'currentYear') {
          if (variables.type === 'month') {
            if (variables.filterType === 'shop') {
              setIsLoadingMonthShop(false)
              setCurrentYearMonthToDateGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingMonthCountry(false)
              setCurrentYearMonthToDateGraphCountry(data)
            }
          } else if (variables.type === 'week') {
            if (variables.filterType === 'shop') {
              setIsLoadingWeekShop(false)
              setCurrentYearWeekToDateGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingWeekCountry(false)
              setCurrentYearWeekToDateGraphCountry(data)
            }
          } else if (variables.type === 'custom') {
            if (variables.filterType === 'shop') {
              setIsLoadingCustomShop(false)
              setCustomGraphShopCurrentYear(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingCustomCountry(false)
              setCustomGraphCountryCurrentYear(data)
            }
          }
        } else {
          if (variables.type === 'month') {
            if (variables.filterType === 'shop') {
              setIsLoadingMonthShop(false)
              setMonthToDateGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingMonthCountry(false)
              setMonthToDateGraphCountry(data)
            }
          } else if (variables.type === 'week') {
            if (variables.filterType === 'shop') {
              setIsLoadingWeekShop(false)
              setWeekToDateGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingWeekCountry(false)
              setWeekToDateGraphCountry(data)
            }
          } else if (variables.type === 'year') {
            if (variables.filterType === 'shop') {
              setIsLoadingYearShop(false)
              setYearToDateGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingYearCountry(false)
              setYearToDateGraphCountry(data)
            }
          } else if (variables.type === 'custom') {
            if (variables.filterType === 'shop') {
              setIsLoadingCustomShop(false)
              setCustomGraphShop(data)
            } else if (variables.filterType === 'country') {
              setIsLoadingCustomCountry(false)
              setCustomGraphCountry(data)
            }
          }
        }
      },
      onError: (error) => {
        setError(error.message || 'Some Error Occurred, Please Try Again')
      }
    }
  )
  useQuery(
    ['getShipment'],
    async () => {
      const response = await purchaseOrderService.getShipmentData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'shipment_country')
        const sortRes = response.map((item) => item.shipment_country)
        setShipment(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )
  useQuery(
    ['getWarehouse'],
    async () => {
      const response = await purchaseOrderService.getWarehouseData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'warehouse')
        const sortRes = response.map((item) => item.warehouse)
        setWareHouse(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )
  useQuery(
    ['getShop'],
    async () => {
      const response = await purchaseOrderService.getShopData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'shop')
        const sortRes = response.map((item) => item.shop)
        setShop(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )
  const fetchDataShop = async () => {
    try {
      setIsLoadingMonthShop(true)
      setIsLoadingWeekShop(true)
      if (selectedYear !== 'currentYear') {
        setIsLoadingYearShop(true)
      }
      if (newParam.get('start_date') && newParam.get('end_date')) {
        setIsLoadingCustomShop(true)
      }

      const { startDate: monthStartDate, endDate: monthEndDate } = getMonthToDate()
      const { startDate: yearStartDate, endDate: yearEndDate } = getYearToDate()
      const { startDate: weekStartDate, endDate: weekEndDate } = getWeekToDate()

      const filterTypeShop = 'shop'

      // Call APIs based on selectedOption and selectedShipmentCountry
      if (selectedOption || selectedShipmentCountry) {
        // Call APIs for shop based on selectedOption
        if (selectedOption === 'monthToDate') {
          await getSalesData({
            startDate: monthStartDate,
            endDate: monthEndDate,
            filterType: filterTypeShop,
            country: selectedShipmentCountry,
            type: 'month',
            year: selectedYear,
            commerce_type: newParam.get('commerce_type_shop')
          })
        } else if (selectedOption === 'weekToDate' && !newParam.get('start_date') && !newParam.get('end_date')) {
          await getSalesData({
            startDate: weekStartDate,
            endDate: weekEndDate,
            filterType: filterTypeShop,
            country: selectedShipmentCountry,
            type: 'week',
            year: selectedYear,
            commerce_type: newParam.get('commerce_type_shop')
          })
        } else if (selectedOption === 'yearToDate' && selectedYear !== 'currentYear') {
          await getSalesData({
            startDate: yearStartDate,
            endDate: yearEndDate,
            filterType: filterTypeShop,
            country: selectedShipmentCountry,
            type: 'year',
            year: selectedYear,
            commerce_type: newParam.get('commerce_type_shop')
          })
        } else if (newParam.get('start_date') && newParam.get('end_date')) {
          await getSalesData({
            startDate: newParam.get('start_date'),
            endDate: newParam.get('end_date'),
            filterType: filterTypeShop,
            country: selectedShipmentCountry,
            type: 'custom',
            year: selectedYear,
            commerce_type: newParam.get('commerce_type_shop')
          })
        }

        setIsLoadingMonthShop(false)
        setIsLoadingWeekShop(false)
        if (selectedYear !== 'currentYear') {
          setIsLoadingYearShop(false)
        }
        if (newParam.get('start_date') && newParam.get('end_date')) {
          setIsLoadingCustomShop(false)
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setIsLoadingMonthShop(false)
      setIsLoadingWeekShop(false)
      if (selectedYear !== 'currentYear') {
        setIsLoadingYearShop(false)
      }
      if (newParam.get('start_date') && newParam.get('end_date')) {
        setIsLoadingCustomShop(false)
      }
    }
  }
  const fetchDataSku = async () => {
    try {
      setIsLoadingMonthCountry(true)
      setIsLoadingWeekCountry(true)
      if (selectedYear !== 'currentYear') {
        setIsLoadingYearCountry(true)
      }
      if (newParam.get('sku_start_date') && newParam.get('sku_end_date')) {
        setIsLoadingCustomCountry(true)
      }

      const { startDate: monthStartDate, endDate: monthEndDate } = getMonthToDate()
      const { startDate: yearStartDate, endDate: yearEndDate } = getYearToDate()
      const { startDate: weekStartDate, endDate: weekEndDate } = getWeekToDate()

      const filterTypeCountry = 'country'

      // Call APIs based on selectedShop and skuSelectedOption
      if (selectedShop || skuSelectedOption) {
        // Call APIs for country based on skuSelectedOption
        if (skuSelectedOption === 'monthToDate') {
          await getSalesData({
            startDate: monthStartDate,
            endDate: monthEndDate,
            filterType: filterTypeCountry,
            type: 'month',
            shop: selectedShop,
            year: selectedYear
          })
        } else if (
          skuSelectedOption === 'weekToDate' &&
          !newParam.get('sku_start_date') &&
          !newParam.get('sku_end_date')
        ) {
          await getSalesData({
            startDate: weekStartDate,
            endDate: weekEndDate,
            filterType: filterTypeCountry,
            type: 'week',
            shop: selectedShop,
            year: selectedYear
          })
        } else if (skuSelectedOption === 'yearToDate' && selectedYear !== 'currentYear') {
          await getSalesData({
            startDate: yearStartDate,
            endDate: yearEndDate,
            filterType: filterTypeCountry,
            shop: selectedShop,
            type: 'year',
            year: selectedYear
          })
        } else if (newParam.get('sku_start_date') && newParam.get('sku_end_date')) {
          await getSalesData({
            startDate: newParam.get('sku_start_date'),
            endDate: newParam.get('sku_end_date'),
            filterType: filterTypeCountry,
            shop: selectedShop,
            type: 'custom',
            year: selectedYear
          })
        }
        setIsLoadingMonthCountry(false)
        setIsLoadingWeekCountry(false)
        if (selectedYear !== 'currentYear') {
          setIsLoadingYearCountry(false)
        }
        if (newParam.get('sku_start_date') && newParam.get('sku_end_date')) {
          setIsLoadingCustomCountry(false)
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setIsLoadingMonthCountry(false)
      setIsLoadingWeekCountry(false)
      if (selectedYear !== 'currentYear') {
        setIsLoadingYearCountry(false)
      }
      if (newParam.get('sku_start_date') && newParam.get('sku_end_date')) {
        setIsLoadingCustomCountry(false)
      }
    }
  }
  const [shops, setShops] = useState([])
  const defaultShop = [
    'Amazon DE',
    'Amazon DE B2B',
    'Amazon DI DE',
    'Amazon DI FR',
    'Amazon DI UK',
    'Amazon DS DE',
    'Amazon DS UK',
    'Amazon UK',
    'Amazon FR B2B',
    'Amazon UK B2B',
    'Adeo',
    'Bol',
    'But',
    'CDiscount',
    'Conforama',
    'Fonq Shop',
    'Home24 AT',
    'Home24 CH',
    'Home24 DE',
    'Home24 FR',
    'Homedeco NL',
    'INNO',
    'Maison',
    'Manomano DE',
    'Manomano ES',
    'Manomano FR',
    'Manomano IT',
    'Otto Shop',
    'Otto_DE',
    'Rug Direct',
    'Rug Love'
  ]
  const [multipleShopNames, setMultipleShopNames] = useState(defaultShop)
  const handleResetFilters = (type) => {
    if (type === 'SHOPS') {
      setMultipleShopNames(defaultShop)
      setSelectedOption('weekToDate')
      setSelectedShipmentCountry('')
      navigate({ pathname: `/analytics` })
    } else if (type === 'SKU') {
      setWareHouse([])
      setSelectedPercent('')
      setSelectedShipmentCountrySku('')
      setSelectedShop('')
      setSkuSelectedOption('weekToDate')
      navigate({ pathname: `/analytics` })
    }
  }
  const initilizeList = async () => {
    setLoading(true)
    try {
      const dateFormet = await CalculateDate(selectedOption)
      let query = {}
      if (newParam.get('start_date') && newParam.get('end_date')) {
        query.start_date = newParam.get('start_date')
        query.end_date = newParam.get('end_date')
      } else {
        query.start_date = dateFormet.start_date
        query.end_date = dateFormet.end_date
      }
      if (selectedShipmentCountry) {
        query.shipment_country = selectedShipmentCountry
      }
      if (newParam.get('commerce_type_shop')) {
        query.commerce_type = newParam.get('commerce_type_shop')
      }
      query.year = selectedYear
      const queryParams = new URLSearchParams(query)
      const apiRes = await purchaseOrderService.getSaleslisting(queryParams.toString())
      let periodData = (await apiRes?.data?.period_data) || []
      await sortAlphabeticOrder(periodData, 'shop')
      if (newParam.get('search_shop')) {
        periodData = await periodData.filter((item) =>
          item.shop.toLowerCase().includes(newParam.get('search_shop').toLowerCase())
        )
      }
      if (multipleShopNames.length) {
        periodData = await periodData.filter((item) => multipleShopNames.includes(item.shop))
        return setShops({ period_data: periodData, total_count: periodData.length })
      }
      setShops({ period_data: periodData, total_count: periodData.length })
    } catch (error) {
      setLoading(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  async function filterData(apiRes, selectedPercent) {
    if (selectedPercent === 'mostIncreased') {
      const increasedData = apiRes?.period_data?.filter((item) => {
        if (item?.sku === '') {
          return false
        }
        const qty2023 = item.qty_ordered_2023 || 0
        const qty2024 = item?.qty_ordered_2024 || 0
        const percentChange = ((qty2024 - qty2023) / qty2023) * 100
        return percentChange >= 15
      })
      const sortedData = await sortAlphabeticOrder(increasedData, 'sku')
      return { period_data: sortedData, total_count: sortedData.length }
    } else if (selectedPercent === 'mostDecreased') {
      const decreasedData = apiRes?.period_data?.filter((item) => {
        if (item.sku === '') {
          return false
        }
        const qty2023 = item.qty_ordered_2023 || 0
        const qty2024 = item.qty_ordered_2024 || 0
        const percentChange = ((qty2024 - qty2023) / qty2023) * 100
        return percentChange <= -15
      })
      const sortedData = await sortAlphabeticOrder(decreasedData, 'sku')
      return { period_data: sortedData, total_count: sortedData.length }
    } else {
      const filteredData = await apiRes?.period_data?.filter((item) => item.sku !== '')
      const sortedData = await sortAlphabeticOrder(filteredData, 'sku')
      let periodData = sortedData || []
      if (newParam.get('search_sku')) {
        periodData = await periodData.filter((item) =>
          item.sku.toLowerCase().includes(newParam.get('search_sku').toLowerCase())
        )
        return { period_data: periodData, total_count: periodData.length }
      }
      return { period_data: periodData, total_count: periodData.length }
      // return { period_data: sortedData, total_count: sortedData.length }
    }
  }
  const [shopsSku, setShopsSku] = useState([])
  const [skuLoading, setSkuLoading] = useState(false)
  const initilizeSkuList = async () => {
    setSkuLoading(true)
    try {
      const dateFormet = await CalculateDate(skuSelectedOption)
      let payload = {}
      if (newParam.get('sku_start_date') && newParam.get('sku_end_date')) {
        payload.start_date = newParam.get('sku_start_date')
        payload.end_date = newParam.get('sku_end_date')
      } else {
        payload.start_date = dateFormet.start_date
        payload.end_date = dateFormet.end_date
      }
      if (selectedShop) {
        payload.shop = selectedShop
      }
      if (selectedPercent) {
        payload.recent_filter = selectedPercent
      }
      if (selectedShipmentCountrySku) {
        payload.shipment_country = selectedShipmentCountrySku
      }
      if (newParam.get('warehouse')) {
        payload.warehouse = newParam.get('warehouse')
      }
      if (newParam.get('commerce_type_sku')) {
        payload.commerce_type_sku = newParam.get('commerce_type_sku')
      }
      payload.year = selectedYear
      payload.sku = true
      const queryParams = new URLSearchParams(payload)
      const apiRes = await purchaseOrderService.getSaleslisting(queryParams.toString())
      const filterRes = await filterData(apiRes?.data, selectedPercent)
      await setShopsSku(filterRes)
      await setFilteredSkus(filterRes?.period_data)
    } catch (error) {
      setSkuLoading(false)
      console.log(error)
    } finally {
      setSkuLoading(false)
    }
  }
  const [shopCountryData, setShopCountryData] = useState([])
  const [shopCountryDataLoading, setshopCountryDataLoading] = useState(false)
  const fetchDataPaticularShop = async (shop) => {
    setshopCountryDataLoading(true)
    try {
      const dateFormet = await CalculateDate(selectedOption)
      let query = {}
      if (newParam.get('start_date') && newParam.get('end_date')) {
        query.start_date = newParam.get('start_date')
        query.end_date = newParam.get('end_date')
      } else {
        query.start_date = dateFormet.start_date
        query.end_date = dateFormet.end_date
      }
      if (selectedShipmentCountry) {
        query.shipment_country = selectedShipmentCountry
      }
      query.shop = shop
      const queryParams = new URLSearchParams(query)
      const apiRes = await purchaseOrderService.getSalesReportByCountry(queryParams)
      setShopCountryData(apiRes?.data)
    } catch (error) {
    } finally {
      setshopCountryDataLoading(false)
    }
  }
  useEffect(() => {
    const { startDate: monthStartDate, endDate: monthEndDate } = getMonthToDate()
    const { startDate: yearStartDate, endDate: yearEndDate } = getYearToDate()
    const { startDate: weekStartDate, endDate: weekEndDate } = getWeekToDate()
    let dateObj = {
      week: { startDate: weekStartDate, endDate: weekEndDate },
      month: { startDate: monthStartDate, endDate: monthEndDate },
      year: { startDate: yearStartDate, endDate: yearEndDate }
    }
    if (yearSelectStartDate && yearSelectEndDate) {
      dateObj.rangeFilter = { startDate: new Date(yearSelectStartDate), endDate: new Date(yearSelectEndDate) }
    }
    if (newParam.get('revenue_warehouse')) {
      dateObj.revenue_warehouse = newParam.get('revenue_warehouse')
    }
    if (newParam.get('commerce_type')) {
      dateObj.commerce_type = newParam.get('commerce_type')
    }
    getSales(dateObj)
  }, [yearSelectEndDate, yearSelectStartDate, newParam.get('revenue_warehouse'), newParam.get('commerce_type')])

  useEffect(() => {
    fetchDataSku()
  }, [
    skuSelectedOption,
    selectedShop,
    selectedShipmentCountrySku,
    selectedYear,
    newParam.get('sku_start_date'),
    newParam.get('sku_end_date')
  ])

  useEffect(() => {
    fetchDataShop()
  }, [
    selectedOption,
    selectedShipmentCountry,
    selectedYear,
    newParam.get('start_date'),
    newParam.get('end_date'),
    newParam.get('search_shop'),
    newParam.get('commerce_type_shop')
  ])

  useEffect(() => {
    GetAllDiscontinuedProducts()
  }, [selectedSkuFilter])

  useEffect(() => {
    initilizeList()
  }, [
    selectedShipmentCountry,
    selectedOption,
    selectedYear,
    newParam.get('start_date'),
    newParam.get('end_date'),
    newParam.get('search_shop'),
    newParam.get('commerce_type_shop'),
    multipleShopNames
  ])

  useEffect(() => {
    initilizeSkuList()
  }, [
    skuSelectedOption,
    selectedShipmentCountrySku,
    selectedYear,
    selectedShop,
    selectedPercent,
    newParam.get('sku_start_date'),
    newParam.get('sku_end_date'),
    newParam.get('warehouse'),
    newParam.get('commerce_type_sku'),
    newParam.get('search_sku')
  ])

  return {
    yearSelectStartDate,
    setYearSelectStartDate,
    yearSelectEndDate,
    setYearSelectEndDate,
    sales,
    totalRevenueLoad,
    filteredSkus,
    selectedSkuFilter,
    setSelectedSkuFilter,
    error,
    setSelectedOption,
    setSkuSelectedOption,
    skuSelectedOption,
    selectedOption,
    shops,
    shopsSku,
    shipment,
    setSelectedYear,
    selectedYear,
    shop,
    wareHouse,
    selectedPercent,
    setSelectedPercent,
    setSelectedShop,
    selectedShop,
    selectedShipmentCountry,
    setSelectedShipmentCountry,
    selectedShipmentCountrySku,
    setSelectedShipmentCountrySku,
    getSalesData,
    isSalesLoading,
    salesData,
    monthToDateGraphShop,
    weekToDateGraphShop,
    yearToDateGraphShop,
    monthToDateGraphCountry,
    weekToDateGraphCountry,
    yearToDateGraphCountry,
    isLoadingMonthShop,
    isLoadingWeekShop,
    isLoadingYearShop,
    isLoadingMonthCountry,
    isLoadingWeekCountry,
    isLoadingYearCountry,
    currentYearMonthToDateGraphShop,
    currentYearMonthToDateGraphCountry,
    currentYearWeekToDateGraphShop,
    currentYearWeekToDateGraphCountry,
    isLoadingCustomShop,
    isLoadingCustomCountry,
    customGraphShop,
    customGraphCountry,
    customGraphShopCurrentYear,
    customGraphCountryCurrentYear,
    skuLoading,
    fetchDataPaticularShop,
    shopCountryData,
    shopCountryDataLoading,
    multipleShopNames,
    setMultipleShopNames,
    handleResetFilters
  }
}

import React from 'react'

import './Loader.scss'

const Loader = () => {
  return (
    <div data-testid='t-loader-wrapper' className='loader-wrapper'>
      <div data-testid='t-loading-widget' className='loading-widget' />
    </div>
  )
}

export default Loader

import { useMutation, useQuery } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { exportJsonToCsv, readExcel, sortAlphabeticOrder } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'

export const useAdvertisementHooks = () => {
  const navigate = useNavigate()
  const locations = useLocation()
  const newParam = new URLSearchParams(location.search)
  const [error, setError] = useState(null)
  const [shipment, setShipment] = useState([])
  const [shop, setShop] = useState([])
  const [selectedShop, setSelectedShop] = useState('')
  const [selectedShipmentCountry, setSelectedShipmentCountry] = useState('')
  const [importFilter, setImportFilter] = useState({ country: '', shop: '' })
  const [adGroups, setAdGroups] = useState([])
  const [selectedAdGroup, setSelectedAdGroup] = useState('')
  const [productSku, setProductSku] = useState()
  const [advertisementReport, setAdvertisementReport] = useState()
  const [totalRevenue, setTotalRevenue] = useState()
  const [graphDetails, setGraphDetails] = useState({ acosData: 0, spendData: 0, xLabels: [], sales: [] })
  const [reportLoadng, setReportLoadng] = useState(false)
  const [skuData, setSkuData] = useState([])
  const [skupage, setSkuPage] = useState(0)
  const [skurowsPerPage, setSkuRowsPerPage] = useState(50)
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [advertisementData, setAdvertisementData] = useState()
  const [loading, setLoading] = useState(false)
  const [periodType, setPeriodType] = useState('yearToDate')
  const { showToast } = useContext(UserContext)
  const [allAdvertisementData, setallAdvertisementData] = useState([])
  const [rawpage, setRawPage] = useState(1)
  const [rawloading, setRawLoading] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)

  const [totalDocuments, setTotalDocuments] = useState(0)
  const [selectedShopRaw, setSelectedShopRaw] = useState('')
  const [selectedShipmentCountryRaw, setSelectedShipmentCountryRaw] = useState('')
  const [productSkuRaw, setProductSkuRaw] = useState()
  const [selectedAdGroupRaw, setSelectedAdGroupRaw] = useState('')
  const [startDateRaw, setstartDateRaw] = useState('')
  const [endDateRaw, setendDateRaw] = useState('')
  const [lastUpdatedDate, setLastUpdatedDate] = useState({})


  const getMonthToDate = () => {
    const today = new Date()
    const startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1))
    const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59))
    return { startDate: startDate, endDate: endDate }
  }
  const getYearToDate = () => {
    const today = new Date()
    const startDate = new Date(Date.UTC(today.getFullYear(), 0, 1))
    const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59))
    return { startDate: startDate, endDate: endDate }
  }
  const getWeekToDate = () => {
    const today = new Date()
    const dayOfWeek = today.getUTCDay()
    const startOfPreviousWeek = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek - 6)
    )
    const endOfPreviousWeek = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek, 23, 59, 59)
    )
    return {
      startDate: startOfPreviousWeek,
      endDate: endOfPreviousWeek
    }
  }

  function getPercentageDifference(num1, num2) {
    if (num1 === 0 || num2 === 0) {
      return 0
    }
    const percentageDifference = (num1 / num2) * 100
    return percentageDifference
  }

  useQuery(
    ['getShipment'],
    async () => {
      const response = await purchaseOrderService.getShipmentData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'shipment_country')
        const sortRes = response.map((item) => item?.shipment_country)
        setShipment(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )

  const [filteredShipment, setFilteredShipment] = useState([])
  const FilterData = async () => {
    try {
      let query = {}
      if (selectedShop) {
        query.shop = selectedShop
      }
      if (selectedShipmentCountry) {
        query.country = selectedShipmentCountry
      }
      if (selectedAdGroup) {
        query.adgroup = selectedAdGroup
      }
      const queryParams = new URLSearchParams(query)
      const apiRes = await purchaseOrderService.getFilterListingAdvertisment(queryParams.toString())
      const data = await apiRes.data
      setAdGroups(data?.adgroups)
      setShop(data?.shops)
      setFilteredShipment(data?.countries)
    } catch (error) {
      console.log(error)
    }
  }

  const getAdvertismentData = async (rawpage, filters = {}) => {
    setRawLoading(true)
    try {
      const response = await purchaseOrderService.getAllAdvertismentData(rawpage, filters)
      const data = await response.data.data
      setTotalDocuments(response.data.totalDocuments)
      setallAdvertisementData((prevData) => (rawpage === 1 ? data : [...prevData, ...data]))
      setRawPage(rawpage + 1)
    }
    catch (error) {
      setError(error.response?.data?.message || 'Some error occurred')
    }
    finally {
      setRawLoading(false)
    }
  }

  const loadMoreAdvertisementData = useCallback(async () => {
    if (rawloading || allAdvertisementData.length >= totalDocuments) return
    setRawLoading(true)
    try {
      await getAdvertismentData(rawpage, {
        selectedShopRaw,
        selectedShipmentCountryRaw,
        selectedAdGroupRaw,
        productSkuRaw,
        start_date: startDateRaw,
        end_date: endDateRaw
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setRawLoading(false)
    }
  }, [rawloading, rawpage, allAdvertisementData.length, totalDocuments, selectedShopRaw, selectedShipmentCountryRaw, selectedAdGroupRaw, productSkuRaw, startDateRaw, endDateRaw])

  const applyFilters = async () => {
    setRawPage(1)
    setallAdvertisementData([])
    setButtonLoader(true)
    try {
      await getAdvertismentData(1, {
        selectedShopRaw,
        selectedShipmentCountryRaw,
        selectedAdGroupRaw,
        productSkuRaw,
        start_date: startDateRaw,
        end_date: endDateRaw
      })
    }
    catch (error) {

    }
    finally {
      setButtonLoader(false)
    }
  }

  useEffect(() => {
    if (locations.pathname === '/advertisement/rawData') {
      getAdvertismentData(1)
    }
  }, [locations.pathname === '/advertisement/rawData'])

  const resetFilters = async () => {
    setSelectedShopRaw('')
    setSelectedAdGroupRaw('')
    setSelectedShipmentCountryRaw('')
    setProductSkuRaw('')
    setstartDateRaw('')
    setendDateRaw('')
    await getAdvertismentData(1)
  }

  useEffect(() => {
    FilterData()
  }, [selectedShop, selectedShipmentCountry, selectedAdGroup])

  const getAdvertisementReport = async () => {
    setReportLoadng(true)
    const startDate = newParam.get('start_date')
    const endDate = newParam.get('end_date')
    try {
      let payload = {}
      if (productSku && productSku.length > 3) {
        payload.sku = productSku
      }
      if (selectedShop) {
        payload.shop = selectedShop
      }
      if (selectedShipmentCountry) {
        payload.country = selectedShipmentCountry
      }
      if (selectedAdGroup) {
        payload.adgroup = selectedAdGroup
      }
      let comparisonPeriod = {}
      if (periodType === 'monthToDate') {
        comparisonPeriod = getMonthToDate()
      } else if (periodType === 'yearToDate') {
        comparisonPeriod = getYearToDate()
      } else if (periodType === 'weekToDate') {
        comparisonPeriod = getWeekToDate()
      }
      if (startDate && endDate) {
        payload.start_date = startDate
        payload.end_date = endDate
      } else {
        payload[periodType] = JSON.stringify({
          startDate: comparisonPeriod.startDate.toISOString(),
          endDate: comparisonPeriod.endDate.toISOString()
        })
      }
      const queryParams = new URLSearchParams(payload)
      const apiRes = await purchaseOrderService.getAdvertisementReport(queryParams.toString())
      const start = new Date(startDate || comparisonPeriod.startDate)
      const end = new Date(endDate || comparisonPeriod.endDate)
      const dateDifference = (end - start) / (1000 * 60 * 60 * 24)
      const graphData = apiRes?.data.graphData || []
      let xLabels
      if (periodType === 'weekToDate') {
        xLabels = graphData.map((item) => item.day)
      } else if (periodType === 'monthToDate') {
        xLabels = dateDifference > 15 ? graphData.map((item) => item.week) : graphData.map((item) => item.day)
      } else if (periodType === 'yearToDate') {
        xLabels = graphData.map((item) => item.month)
      }
      if (startDate && endDate) {
        xLabels = dateDifference > 15 ? graphData.map((item) => item.week) : graphData.map((item) => item.day)
      }
      let totalSpend = []
      let totalAcos = []
      let salesData = []

      let impressions = []
      let ctr = []
      let conversions = []
      let cvr = []
      await Promise.all(
        apiRes?.data.graphData.map((data, index) => {
          totalSpend.push(data.totalSpend)
          totalAcos.push(getPercentageDifference(data.totalSpend, data.totalRevenue))
          salesData.push(apiRes?.data?.salesGraphData[index]?.untaxedTotal)
          impressions.push(data?.totalImpressions)
          conversions.push(data?.totalConversions)
          ctr.push(getPercentageDifference(data.totalClicks, data.totalImpressions))
          cvr.push(getPercentageDifference(data.totalConversions, data.totalClicks))
        })
      )
      setGraphDetails({
        acosData: totalAcos,
        spendData: totalSpend,
        xLabels: xLabels.slice(0, apiRes?.data.graphData.length),
        sales: salesData,
        cvr: cvr,
        conversions: conversions,
        impressions: impressions,
        ctr: ctr
      })
      setSkuData(apiRes?.data?.skuData)
      setAdvertisementReport(apiRes?.data?.result[0])
      setReportLoadng(false)
    } catch (error) {
      console.log(error)
      setReportLoadng(false)
    } finally {
      setReportLoadng(false)
    }
  }

  const getTotalRevenue = async () => {
    try {
      let payload = {}
      const startDate = newParam.get('start_date')
      const endDate = newParam.get('end_date')
      if (selectedShop) {
        payload.shop = selectedShop
      }
      if (selectedShipmentCountry) {
        payload.country = selectedShipmentCountry
      }
      let comparisonPeriod = {}
      if (periodType === 'monthToDate') {
        comparisonPeriod = getMonthToDate()
      } else if (periodType === 'yearToDate') {
        comparisonPeriod = getYearToDate()
      } else if (periodType === 'weekToDate') {
        comparisonPeriod = getWeekToDate()
      }
      if (startDate && endDate) {
        payload.start_date = startDate
        payload.end_date = endDate
      } else {
        payload[periodType] = JSON.stringify({
          startDate: comparisonPeriod.startDate.toISOString(),
          endDate: comparisonPeriod.endDate.toISOString()
        })
      }
      const queryParams = new URLSearchParams(payload)
      const apiRes = await purchaseOrderService.getTotalRevenue(queryParams.toString())
      setTotalRevenue(apiRes?.data?.result[0])
    } catch (error) {
      console.log(error)
    }
  }

  const handleSkuChangePage = (event, newPage) => {
    setSkuPage(newPage)
  }
  const handleSkuChangeRowsPerPage = (event) => {
    setSkuRowsPerPage(parseInt(event.target.value, 10))
    setSkuPage(0)
  }

  const handleImportModal = () => {
    setImportModalOpen(!importModalOpen)
  }
  const submitData = async () => {

    if (!advertisementData) {
      return showToast('Please select File to import...', false)
    }
    let payload = {
      advertisementData: advertisementData
    }
    setLoading(true)
    await handlePoSubmitMutation.mutateAsync(payload)
  }

  const convertData = async (file) => {
    const data = await readExcel(file)
    const transformedData = data.map((item) => ({
      date: new Date(item.DATE),
      sku: item.SKU,
      adgroup: item['Ad group'],
      impressions: item.Impressions,
      clicks: item.Clicks,
      avgcpc: item['Avg. CPC'],
      conversions: item.Conversions,
      spend: item.Spend,
      revenue: item.Revenue,
      shop: item?.SHOP,
      country: item.COUNTRY

    }))
    setAdvertisementData(transformedData)
  }

  const handlePoSubmitMutation = useMutation(
    async (payload) => {
      return await purchaseOrderService.addAdvertisement(payload)
    },
    {
      onSuccess: () => {
        setLoading(false)
        setImportModalOpen(!importModalOpen)
      },
      onError: (error) => {
        console.error('Error adding project:', error)
        setLoading(false)
      }
    }
  )

  const onChangeFilter = (name, type) => {
    let dateChanged = new Date(type)
    if (type) {
      setSkuPage(0)
      newParam.set(name, dateChanged)
    } else {
      newParam.delete(name)
      newParam.delete('end_date')
      newParam.delete('sku_end_date')
    }
    navigate({ search: newParam.toString() })
  }

  useEffect(() => {
    getAdvertisementReport()
    getTotalRevenue()
  }, [
    periodType,
    productSku,
    selectedShop,
    selectedShipmentCountry,
    selectedAdGroup,
    newParam.get('start_date'),
    newParam.get('end_date')
  ])

  const checkLastImportedDate = async () => {
    const query = {}
    if (importFilter?.shop && importFilter?.country) {
      query.shop = importFilter?.shop
      query.country = importFilter?.country
    }
    const queryParams = new URLSearchParams(query)
    getLastImportedDate(queryParams)
  }

  const { mutate: getLastImportedDate, isLoading: lastImportedDateLoading } = useMutation(async (payload) => {
    return await purchaseOrderService.getLastImportedDate(payload)
  },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setLastUpdatedDate(response?.data)
      },
      onError: (error) => {
        0
        console.error('Error adding project:', error)
      }
    }
  )

  useEffect(() => {
    if (importFilter?.shop && importFilter?.country) {
      checkLastImportedDate()
    }
  }, [importFilter])

  const onChangeImported = (name, value) => {
    setImportFilter({ ...importFilter, [name]: value })
  }

  const ExportData = async (data) => {
    // Map the data to only include the necessary columns
    const filteredData = data.map((item) => {
      return {
        design: item?.sku ? item?.sku.split('-')[0] : '--',
        sku: item?.sku ? item?.sku : '--',
        spend: item?.spend ? item?.spend.toFixed(2) : '--',
        revenue: item?.revenue ? item?.revenue.toFixed(2) : '--',
        acos: item?.spend && item?.revenue && item.revenue !== 0 ? ((item.spend / item?.revenue) * 100).toFixed(2) : '--',
        conversions: item?.conversions ? item?.conversions : '--',
      }
    })

    return exportJsonToCsv(filteredData, 'Advertisement Report')
  }

  return {
    startDateRaw,
    setstartDateRaw,
    endDateRaw,
    setendDateRaw,
    resetFilters,
    applyFilters,
    selectedShopRaw,
    selectedShipmentCountryRaw,
    selectedAdGroupRaw,
    productSkuRaw,
    setSelectedAdGroupRaw,
    setSelectedShipmentCountryRaw,
    setSelectedShopRaw,
    setProductSkuRaw,
    loadMoreAdvertisementData,
    rawloading,
    navigate,
    ExportData,
    shipment,
    getAdvertismentData,
    allAdvertisementData,
    shop,
    filteredShipment,
    setSelectedShop,
    selectedShop,
    setSelectedShipmentCountry,
    selectedShipmentCountry,
    error,
    adGroups,
    selectedAdGroup,
    setSelectedAdGroup,
    getAdvertisementReport,
    productSku,
    setProductSku,
    advertisementReport,
    totalRevenue,
    setTotalRevenue,
    graphDetails,
    getPercentageDifference,
    reportLoadng,
    skuData,
    newParam,
    skurowsPerPage,
    setSkuRowsPerPage,
    skupage,
    setSkuPage,
    importModalOpen,
    setImportModalOpen,
    advertisementData,
    setAdvertisementData,
    loading,
    buttonLoader,
    setLoading,
    handleSkuChangePage,
    handleSkuChangeRowsPerPage,
    handleImportModal,
    submitData,
    convertData,
    onChangeFilter,
    handlePoSubmitMutation,
    setPeriodType,
    periodType,
    onChangeImported,
    importFilter,
    lastImportedDateLoading,
    lastUpdatedDate
  }
}

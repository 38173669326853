import React, { useContext, useState } from 'react'
import { IconButton, Tooltip, Grid, TablePagination, TextField, Select, MenuItem, FormControl, InputLabel, TableRow, TableCell, Table, TableHead, TableBody, TableContainer } from '@mui/material'
import PropTypes from 'prop-types'
import './User.css'
import { FiEdit } from 'react-icons/fi'
import image from '../../../assets/images/profile_placeholder.png'
import { UserContext } from 'context/AuthContext/UserContext'

const User = (props) => {
  const { users = [], userType, page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleDeactivatedUsersClick, handleActivatedUsersClick, setisAddUser, setParticularUserId } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRole, setSelectedRole] = useState('')
  const { newParam, onChangeRouter, userData } = useContext(UserContext)
  const selectedStatus = newParam.get('USER') || 'All'
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setPage(0)
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value)
    setPage(0)
  }

  const handleStatusChange = (event) => {
    if (event.target.value === "Deactivated") {
      handleDeactivatedUsersClick()
    }
    else {
      handleActivatedUsersClick()
    }
    setPage(0)
  }

  const handleRoleClick = (roleCode) => {
    setSelectedRole(roleCode)
    setPage(0)
  }

  function getUserCount(roleCode) {
    const count = users.filter(user => user?.userSubscription?.roleCode === roleCode).length
    return `${count} Total Users`
  }

  const data = [
    { title: 'Read', amount: getUserCount(2), color: '#367588', roleCode: 2 },
    { title: 'Write', amount: getUserCount(3), color: '#214946', roleCode: 3 },
    { title: 'Admin', amount: getUserCount(4), color: '#367588', roleCode: 4 },
    { title: 'Super Admin', amount: getUserCount(5), color: '#214946', roleCode: 5 },
    { title: 'Customer Support Access', amount: getUserCount(6), color: '#367588', roleCode: 6 },
  ]

  const filteredUsers = users.filter(user => {
    const fullName = `${user.fName} ${user.lName}`.toLowerCase()
    const matchesSearch = fullName.includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesRole = selectedRole ? user?.userSubscription?.roleCode === selectedRole : true
    const matchesStatus = selectedStatus === 'All' ? true : selectedStatus === 'Activated' ? user?.isActive : selectedStatus === 'Deactivated' ? !user?.isActive : true
    return matchesSearch && matchesRole && matchesStatus
  })
  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const checkUserType = userData?.user?.pomUserType === 1 ? data : data.slice(0, 4)
  return (
    <>
      {userType ? <Grid container spacing={2}>
        {checkUserType.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} mt={2} key={index}>
            <div
              onClick={() => handleRoleClick(item.roleCode)}
              style={{
                backgroundColor: item.color,
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: '150px',
                width: '100%'
              }}
            >
              <h3 style={{ margin: '0', color: '#fff' }}>{item.title}</h3>
              <p style={{ margin: '5px 0 0 0', color: '#fff' }}>{item.amount}</p>
            </div>
          </Grid>
        ))}
      </Grid> : ("")}
      {userType ? (<h2 className='mt-2' style={{ fontSize: '1.5rem', textAlign: 'left', padding: '10px 0' }}>
        Roles List With Details
      </h2>) : ("")}

      <Grid container spacing={2} className='mt-3 mb-4'>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Search User"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        {userType ? <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Search Role</InputLabel>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              label="Search Role"
            >
              <MenuItem value=""><em>Search Role</em></MenuItem>
              <MenuItem value={2}>Read</MenuItem>
              <MenuItem value={3}>Write</MenuItem>
              <MenuItem value={4}>Admin</MenuItem>
              <MenuItem value={5}>Super Admin</MenuItem>
              {userData?.user?.pomUserType === 1 && <MenuItem value={6}>Customer Support Access</MenuItem>}
            </Select>
          </FormControl>
        </Grid> : ("")}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Search Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => { handleStatusChange(e), onChangeRouter('USER', e.target.value) }}
              label="Search Status"
            >
              <MenuItem value=""><em>Search Users</em></MenuItem>
              <MenuItem value="Activated">Activated</MenuItem>
              <MenuItem value="Deactivated">Deactivated</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid >
      <TableContainer style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              {!userType ? (<TableCell><strong>Company Name</strong></TableCell>) : ("")}
              {userType ? (<TableCell><strong>Role</strong></TableCell>) : ("")}
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Last Logged In</strong></TableCell>
              <TableCell><strong>{userType ? 'Action' : ""}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(paginatedUsers) && paginatedUsers.length ? (
              paginatedUsers.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Tooltip
                      title={<img src={item?.image ? item?.image : image} alt="Product" width="200px" />}
                      arrow
                      placement="right"
                      PopperProps={{
                        style: { marginLeft: '10px' } // Adjust the left margin as needed
                      }}
                    >
                      {item?.fName} {item?.lName}
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item?.email}</TableCell>
                  {!userType ? <TableCell>{item?.companyName ? item?.companyName : "N/A"}</TableCell> : ("")}
                  {userType ? (<TableCell>
                    {item?.userSubscription?.pomUserType === 6 ? (
                      "Customer Support Access"
                    ) : item?.userSubscription?.roleCode === 2 ? (
                      "Read"
                    ) : item?.userSubscription?.roleCode === 3 ? (
                      "Write"
                    ) : item?.userSubscription?.roleCode === 4 ? (
                      "Admin"
                    ) : item?.userSubscription?.roleCode === 5 ? (
                      "Super Admin"
                    ) : item?.userSubscription?.roleCode === null ? (
                      "Invalid"
                    ) : (
                      "No Role"
                    )}
                  </TableCell>) : ("")}
                  <TableCell>{item?.isActive ? "Activated" : "Deactivated"}</TableCell>
                  <TableCell>
                    {item?.refreshTokenCreated && item.refreshTokenCreated !== '0001-01-01T00:00:00'
                      ? new Date(item.refreshTokenCreated).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                      }).replace(',', '')
                      : 'N/A'
                    }
                  </TableCell>
                  {userType ? (<TableCell>
                    {setisAddUser && (
                      <Tooltip title={'Edit User'}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <IconButton
                            className='hover-effect'
                            aria-label='Edit User'
                            component='span'
                            onClick={() => { setParticularUserId(item.id); setisAddUser(true) }}
                          >
                            <FiEdit size='0.8em' />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>) : ("")}
                </TableRow>
              ))
            ) : (
              <TableRow >
                <TableCell align='center' colSpan={5}>No users found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className='mt-4'
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

User.propTypes = {
  userType: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  setisAddUser: PropTypes.func.isRequired,
  setParticularUserId: PropTypes.func.isRequired,
  handleDeactivatedUsersClick: PropTypes.func,
  handleActivatedUsersClick: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}

User.defaultProps = {
  handleDeactivatedUsersClick: () => { },
  handleActivatedUsersClick: () => { }
}

export default User

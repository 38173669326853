import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer } from '@mui/material'
import PropTypes from 'prop-types'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { getDate } from 'shared/util/helper'

const RefundTable = (props) => {
  const { skuData = [], skurowsPerPage, skupage, handleSkuChangePage, refundList } = props
  const [sortConfig, setSortConfig] = useState({ type: 'spend', order: 'asc' })
  const tableContainerRef = useRef(null)
  const [allDataLoaded, setAllDataLoaded] = useState(false)

  const sortData = (AdvertisementListData, type, order) => {
    return [...AdvertisementListData].sort((key1, key2) => {
      let Key1Value = key1[type.toLowerCase()]
      let Key2Value = key2[type.toLowerCase()]
      if (type.toLowerCase() === 'acos') {
        Key1Value = (key1.spend / key1.revenue) * 100
        Key2Value = (key2.spend / key2.revenue) * 100
        if (!isFinite(Key1Value)) Key1Value = 0
        if (!isFinite(Key2Value)) Key2Value = 0
      }
      if (Key1Value === undefined || Key1Value === null) Key1Value = 0
      if (Key2Value === undefined || Key2Value === null) Key2Value = 0
      return order === 'asc' ? Key1Value - Key2Value : Key2Value - Key1Value
    })
  }

  const sortedData = useMemo(() => sortData([...skuData], sortConfig.type, sortConfig.order), [skuData, sortConfig])

  const paginatedData = useMemo(() => {
    const start = 0
    const end = (skupage + 1) * skurowsPerPage
    return sortedData.slice(start, end)
  }, [sortedData, skupage, skurowsPerPage])

  const loadMoreData = () => {
    const start = paginatedData.length
    if (sortedData.length > start) {
      handleSkuChangePage('', skupage + 1)
    } else {
      setAllDataLoaded(true)
    }
  }

  const getIconStyle = (type, order) => ({
    color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
    cursor: 'pointer',
    marginLeft: '1px'
  })
  const handleSort = async (type) => {
    const container = tableContainerRef.current
    if (container) {
      await container.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
    setSortConfig({ type, order: newOrder })
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
    if (scrollTop + clientHeight >= scrollHeight - 10 && !allDataLoaded) {
      loadMoreData()
    }
  }
  useEffect(() => {
    const container = tableContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll, true)
      return () => {
        container.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [allDataLoaded, skupage])
  return (
    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
      <TableContainer className='scroll-box' ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><strong>Order Number</strong></TableCell>
              <TableCell><strong>Transaction Type</strong></TableCell>
              <TableCell><strong>Date Of Refund</strong></TableCell>
              <TableCell><strong>Marketplace</strong></TableCell>
              <TableCell><strong>SKU</strong></TableCell>
              <TableCell><strong>Currency</strong></TableCell>
              <TableCell><strong onClick={() => handleSort('acos')}>Refund Amount
                <FaArrowUpLong style={getIconStyle('acos', 'asc')} />
                <FaArrowDownLong className='ms-0' style={getIconStyle('acos', 'desc')} />
              </strong>
              </TableCell>
              <TableCell><strong>Status</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(refundList?.refundList) && refundList?.refundList.length ? (
              refundList?.refundList.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item?.order_id ? item?.order_id : '--'}</TableCell>
                  <TableCell>{item?.transaction_type ? item?.transaction_type : '--'}</TableCell>
                  <TableCell> {item?.date_of_refund ? getDate(item?.date_of_refund) : '--'}</TableCell>
                  <TableCell> {item?.marketplace_name ? item?.marketplace_name : '--'}</TableCell>
                  <TableCell> {item?.sku ? item?.sku : '--'}</TableCell>
                  <TableCell> {item?.currency ? item?.currency : '--'}</TableCell>
                  <TableCell>{item?.return_fee_number ? item?.return_fee_number.toFixed(2) : '--'}</TableCell>
                  <TableCell> {item?.status ? item?.status : '--'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    <p className='text-center'>No Records Found... </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
RefundTable.propTypes = {
  skuData: PropTypes.array,
  skupage: PropTypes.number,
  skurowsPerPage: PropTypes.number,
  handleSkuChangePage: PropTypes.func,
  handleSkuChangeRowsPerPage: PropTypes.func,
  refundList: PropTypes.array
}

export default RefundTable

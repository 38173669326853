import React from 'react'
import PropTypes from 'prop-types'

import './Types.css'

const ShortlistMessage = (props) => {
  return (
    <div className='sc-message--text'>
      {Object.keys(props.data.list).length !== 0 && (
        <>
          <p>Following Product(s) has been shortlisted by you:</p>

          {props.data.list.map((data, ind) => {
            return <p key={ind}>{data}</p>
          })}
          <p className='bold-font'>Any Queries?</p>
        </>
      )}
    </div>
  )
}

ShortlistMessage.propTypes = {
  data: PropTypes.object
}

export default ShortlistMessage

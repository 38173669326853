import React, { useContext } from 'react'
import moment from "moment"
import { FaEnvelope, FaEnvelopeOpen } from "react-icons/fa"
import { Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from 'prop-types'
import { UserContext } from 'context/AuthContext/UserContext'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { Tooltip } from '@mui/material'

const Notificationheader = (props) => {
    const { setLoading, loading, showToast } = useContext(UserContext)
    const navigate = useNavigate()
    const handleNavigation = (data) => {
        if (!data || !data.type || !data.redirect_uri) {
            console.error("Invalid navigation data")
            return
        }
        switch (data.type) {
            case "PURCHASE_ORDER":
                navigate(`/purchaseOrder/${data.redirect_uri}`)
                break
            case "PURCHASE_ORDER_DELIVERY":
                navigate(`/purchaseOrder/delivery/${data.redirect_uri}`)
                break
            case "PRODUCTS":
                navigate(`/products/${data?.redirect_uri}/product-view`)
                break
            default:
                console.error("Unknown type, cannot navigate")
                break
        }
    }
    const markAsRead = async (id) => {
        if (loading) return
        if (props.readNotification) return
        setLoading(true)
        try {
            const apiRes = await purchaseOrderService.markAsRead(id)
            const data = await apiRes.data
            await showToast(data.message, true)
            handleNavigation(data?.data)
            props.refetch()
        } catch (error) {
            showToast(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {props.checkLength ? <li className="ps-2 fw-bold">{props.NotificationHeading}</li> : ''}
            {Array.isArray(props?.Array) && props?.Array?.length ? props?.Array?.map((res, index) => (
                <Fragment key={index}>
                    <li className='px-3 py-2 '>
                        <Link className="dropdown-item notification-hover p-2" to='#' onClick={(e) => {
                            e.preventDefault()
                            if (props?.newNotification) {
                                return markAsRead(res?._id)
                            }
                            else {
                                handleNavigation(res)
                            }
                        }}>
                            <div className="notification-hover notification-text d-flex gap-4  align-items-start" >
                                {props?.newNotification ? <h4 className='fw-bold advertisement-report' ><span ></span></h4> : ""}
                                <Tooltip title={res?.message}>
                                    <p>{props.newNotification ? <FaEnvelope className='me-2' /> : <FaEnvelopeOpen className='me-2' />}{res?.message.length > 30 ? `${res?.message.slice(0, 30)}...` : res?.message ? res?.message : ''}</p>
                                </Tooltip>
                                <p className="ms-auto">{moment(Number(res?.created_at)).fromNow()} </p>
                            </div>
                        </Link>
                    </li>
                    <li className="divider mx-2 my-0"></li>
                </Fragment>
            )) : props?.Notifications ? <li className="mx-2 my-0 py-3 notification-text text-center"><p className="text-grey">{props.Notifications}</p></li> : ''}
            {props?.viewAll ? <Link to={'/notifications?type=all'} className="text-center text-black text-decoration-none"><p>View All</p></Link> : ''}
        </>
    )
}

Notificationheader.propTypes = {
    viewAll: PropTypes.string.isRequired,
    Notifications: PropTypes.string.isRequired,
    checkLength: PropTypes.string.isRequired,
    NotificationHeading: PropTypes.string.isRequired,
    Array: PropTypes.array.isRequired,
    readNotification: PropTypes.string.isRequired,
    refetch: PropTypes.func.isRequired,
    newNotification: PropTypes.bool.isRequired,
}

export default Notificationheader
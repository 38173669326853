import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button } from '@mui/material'
import { PDFExport } from '@progress/kendo-react-pdf'
import './analytics.css'
import InvoiceTemplate from './InvoiceTemplate'
import { UserContext } from 'context/AuthContext/UserContext'




const AnalyticTemplate = (props) => {
    const { showToast } = useContext(UserContext)
    const { shops, shopCountryData, selectedYear, yearSelectStartDate, yearSelectEndDate, selectedShipmentCountry, selectedOption, searchShop, weekSales, monthSales,
        yearSales, sales, selectedPdfType, shopsSku, skuSelectedOption, searchSku, selectedShop, selectedShipmentCountrySku, selectedPercent, skuYearSelectStartDate, skuYearSelectEndDate, } = props
    const [clickedRows, setClickedRows] = React.useState([])
    const pdfExportComponent = React.useRef(null)
    const handleClick = (index) => {
        if (clickedRows.includes(index)) {
            setClickedRows(clickedRows.filter((rowIndex) => rowIndex !== index))
        } else {
            setClickedRows([...clickedRows, index])
        }
    }

    return (
        <>
            <Grid item md={4}>
                <Button variant='outlined' onClick={() => { showToast('Please wait while we fetching the pdf ...', false), pdfExportComponent.current.save() }}>
                    Download
                </Button>
            </Grid>
            <PDFExport
                scale={0.7}
                margin={{ top: 20, left: 5, right: 5, bottom: 40 }}
                keepTogether='p'
                paperSize='A4'
                repeatHeaders={true}
                ref={pdfExportComponent}
            >
                <InvoiceTemplate
                    shops={shops}
                    shopCountryData={shopCountryData}
                    clickedRows={clickedRows}
                    handleClick={handleClick}
                    selectedYear={selectedYear}
                    yearSelectStartDate={yearSelectStartDate}
                    yearSelectEndDate={yearSelectEndDate}
                    selectedShipmentCountry={selectedShipmentCountry}
                    selectedOption={selectedOption}
                    searchShop={searchShop}
                    weekSales={weekSales}
                    monthSales={monthSales}
                    yearSales={yearSales}
                    sales={sales}
                    selectedPdfType={selectedPdfType}
                    shopsSku={shopsSku}
                    skuSelectedOption={skuSelectedOption}
                    searchSku={searchSku}
                    selectedShop={selectedShop}
                    selectedShipmentCountrySku={selectedShipmentCountrySku}
                    selectedPercent={selectedPercent}
                    skuYearSelectStartDate={skuYearSelectStartDate}
                    skuYearSelectEndDate={skuYearSelectEndDate}
                />
            </PDFExport>
        </>
    )
}

AnalyticTemplate.propTypes = {
    shops: PropTypes.array.isRequired,
    shopCountryData: PropTypes.object.isRequired,
    selectedYear: PropTypes.string.isRequired,
    yearSelectStartDate: PropTypes.string.isRequired,
    yearSelectEndDate: PropTypes.string.isRequired,
    selectedShipmentCountry: PropTypes.string.isRequired,
    selectedOption: PropTypes.string.isRequired,
    searchShop: PropTypes.string.isRequired,
    weekSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    monthSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    yearSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sales: PropTypes.object.isRequired,
    selectedPdfType: PropTypes.string.isRequired,
    shopsSku: PropTypes.array.isRequired,
    skuSelectedOption: PropTypes.string.isRequired,
    searchSku: PropTypes.string.isRequired,
    selectedShop: PropTypes.string.isRequired,
    selectedShipmentCountrySku: PropTypes.string.isRequired,
    selectedPercent: PropTypes.string.isRequired,
    skuYearSelectStartDate: PropTypes.string.isRequired,
    skuYearSelectEndDate: PropTypes.string.isRequired
}

export default AnalyticTemplate
import React, { useCallback, useEffect, useRef } from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, CircularProgress, Tooltip, Chip } from '@mui/material'
import PropTypes from 'prop-types'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const TrendsStockTable = (props) => {
    const { stockAlertData = [], setStockAlertPage, loading, setSortConfig, sortConfig, setStockAlertData } = props
    const tableContainerRef = useRef(null)
    const getIconStyle = (type, order) => ({
        color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
        cursor: 'pointer',
        marginLeft: '1px',
    })
    const loadMoreData = () => {
        setStockAlertPage(prevPage => prevPage + 1)
    }
    const handleSort = async (type) => {
        const container = tableContainerRef.current
        if (container) {
            await container.scrollTo({ top: 0, behavior: 'smooth' })
        }
        const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
        await setStockAlertData({ data: [], total_count: 0 })
        await setStockAlertPage(1)
        await setSortConfig({ type, order: newOrder })
    }
    const debounce = (func, wait) => {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => func.apply(this, args), wait)
        }
    }
    const handleScroll = useCallback(debounce(() => {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            loadMoreData()
        }
    }, 300), [])
    useEffect(() => {
        const container = tableContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll, true)
            return () => {
                container.removeEventListener('scroll', handleScroll, true)
            }
        }
    }, [handleScroll])
    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}  >
            <TableContainer className='scroll-box' ref={tableContainerRef}>
                <Table stickyHeader >
                    <TableHead>
                        <TableRow >
                            <TableCell >
                                <strong onClick={() => handleSort('sku')}>SKU
                                    <FaArrowUpLong style={getIconStyle('sku', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('sku', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell ><strong>Image</strong></TableCell>
                            <TableCell >
                                <strong onClick={() => handleSort('total_stock')}>Stock
                                    <FaArrowUpLong style={getIconStyle('total_stock', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('total_stock', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell >
                                <strong onClick={() => handleSort('threshold_quantity')}>TQ
                                    <FaArrowUpLong style={getIconStyle('threshold_quantity', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('threshold_quantity', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell >
                                <strong onClick={() => handleSort('moh_days_stock_remaining')} >MOH (Day stock Remain)
                                    <FaArrowUpLong style={getIconStyle('moh_days_stock_remaining', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('moh_days_stock_remaining', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell >
                                <strong onClick={() => handleSort('final_avg_qty')}>AVG SALES
                                    <FaArrowUpLong style={getIconStyle('final_avg_qty', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('final_avg_qty', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell >
                                <strong onClick={() => handleSort('restock_point')}>Restock Point
                                    <FaArrowUpLong style={getIconStyle('restock_point', 'asc')} />
                                    <FaArrowDownLong className='ms-0' style={getIconStyle('restock_point', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell ><strong >Status</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(Array.isArray(stockAlertData) && stockAlertData.length ? (stockAlertData.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item?.sku ? item?.sku : '--'}</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={<img src={item?.flat_image ? item?.flat_image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`} alt="Product" width="200px" />}
                                            arrow
                                            placement="right"
                                            PopperProps={{
                                                style: { marginLeft: '10px' }
                                            }}
                                        >
                                            <img src={
                                                item?.flat_image ? item?.flat_image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                            } alt="Product" width="60px" height="60px" />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{item?.total_stock ? item?.total_stock : 0}</TableCell>
                                    <TableCell>{item?.threshold_quantity ? item?.threshold_quantity.toFixed(2) : 0}</TableCell>
                                    <TableCell>{item?.current_moh.toFixed(2)} ({item?.moh_days_stock_remaining ? item?.moh_days_stock_remaining.toFixed(2) : 0})</TableCell>
                                    <TableCell>{item?.final_avg_qty ? item?.final_avg_qty.toFixed(2) : 0}</TableCell>
                                    <TableCell>{item?.restock_point ? Math.ceil(item?.restock_point) : 0}</TableCell>
                                    <TableCell>
                                        {item?.stock_status ?
                                            <Chip
                                                label={`${item?.stock_status === 'Over Stock' ? Math.ceil(item?.total_stock - (item?.threshold_quantity * 2)) : item?.stock_status === 'Plan Stock' ? Math.ceil(Math.abs(item?.total_stock - (item?.threshold_quantity * 1.5))) : Math.ceil(item?.restock_point - item?.total_stock)} (${item?.stock_status})`}
                                                color={item?.stock_status === 'Over Stock' ? 'success' : item?.stock_status === 'Plan Stock' ? 'warning' : 'error'}
                                            />
                                            : 0}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        ) : !loading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
                                        <p className='text-center'>No Records Found...</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : "")}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && (
                <div className='d-flex mt-4 justify-content-center align-items-center'>
                    <CircularProgress />
                </div>
            )}
        </Grid>
    )
}
TrendsStockTable.propTypes = {
    stockAlertData: PropTypes.array,
    stockAlertPage: PropTypes.number,
    setStockAlertPage: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    sortConfig: PropTypes.object.isRequired,
    setSortConfig: PropTypes.func.isRequired,
    setStockAlertData: PropTypes.func.isRequired,

}
export default TrendsStockTable

import * as React from 'react'
import { Avatar as MUIAvatar } from '@mui/material'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'

function stringToColor (string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

function stringAvatar (name) {
  let bgcolor = '#F9FAFB'
  let initials = 'HF'
  if (isNotNullUndefinedOrEmpty(name)) {
    const upperCaseName = name.toUpperCase()
    bgcolor = stringToColor(upperCaseName)
    if (upperCaseName.split(' ')[1] !== undefined) {
      initials = `${upperCaseName.split(' ')[0][0]}${upperCaseName.split(' ')[1][0]}`
    } else {
      initials = `${name.toUpperCase().split(' ')[0][0]}`
    }
  }
  return {
    sx: {
      bgcolor
    },
    children: initials
  }
}

const Avatar = (props) => {
  return (
    <MUIAvatar
      alt={props.name}
      src={props.src}
      {...stringAvatar(props.name)}
      className={props.className}
      sx={{
        width: props.width,
        height: props.height,
        bgcolor: isNotNullUndefinedOrEmpty(props.name) && stringToColor(props.name)
      }}
    />
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
}

export default Avatar

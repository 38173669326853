import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TableRow, TableCell, Skeleton, Button, CircularProgress } from '@mui/material'
import { CCol, CFormInput, CFormLabel, CFormSelect } from '@coreui/react'
import { RiRefundLine } from 'react-icons/ri'
import './index.css'
import { useRefundHooks } from './useRefundHooks'
import Constants from 'data/Constants'
import { Link } from 'react-router-dom'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'

const SkeletonRow = ({ cols }) => (
  <TableRow>
    {Array.from({ length: cols }, (_, index) => (
      <TableCell key={index}>
        <Skeleton variant='text' />
      </TableCell>
    ))}
  </TableRow>
)

SkeletonRow.propTypes = {
  cols: PropTypes.number.isRequired
}
const { transactionType, accountType, dispatched, shipped, currencySign } = Constants


const RefundCommericalGesture = () => {
  const { handleInputChange, refundDetails, submitRefundData, errors, loading,shop } = useRefundHooks()
  return (
    <section className='page-spacing'>
      <div className='container-fluid'>
        <div className='common-card rounded-3'>
          <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8} lg={10}>
                <h3 className='fw-bold advertisement-report'>Refund & Commercial Gesture</h3>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button className='mt-3' variant='outlined' component={Link} to='/refund/list'>
                  Refund List&nbsp;&nbsp;
                  <AiOutlineAppstoreAdd size='1.5rem' />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <hr
            style={{
              color: '#000000',
              backgroundColor: '#000000',
              height: 0.5,
              borderColor: '#000000'
            }}
          />
          <form onSubmit={submitRefundData}>
            <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='order_id' className='fontBold col-sm-6 col-form-label'>
                    Order Number<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='text'
                    id='order_id'
                    placeholder='Order Number'
                    onChange={(event) => handleInputChange('order_id', event.target.value)}
                    value={refundDetails.order_id || ''}
                    fullWidth
                  />
                  {errors.order_id && <span style={{ color: 'red' }}>{errors.order_id}</span>}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='sku' className='fontBold col-sm-12 col-form-label'>
                    SKU<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='text'
                    id='sku'
                    placeholder='SKU'
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    onChange={(event) => handleInputChange('sku', event.target.value)}
                    value={refundDetails.sku || ''}
                    fullWidth
                  />
                  {errors.sku && <span style={{ color: 'red' }}>{errors.sku}</span>}
                </CCol>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='marketplace_name' className='fontBold col-sm-12 col-form-label'>
                    Market Place<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    id='marketplace_name'
                    onChange={(event) => handleInputChange('marketplace_name', event.target.value)}
                    value={refundDetails.marketplace_name || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {shop?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.marketplace_name && <span style={{ color: 'red' }}>{errors.marketplace_name}</span>}
                </CCol>
              </Grid>
          

              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='shipped_country' className='fontBold col-sm-12 col-form-label'>
                    Shipped Country<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    id='shipped_country'
                    onChange={(event) => handleInputChange('shipped_country', event.target.value)}
                    value={refundDetails.shipped_country || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {shipped?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.shipped_country && <span style={{ color: 'red' }}>{errors.shipped_country}</span>}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='country_of_dispatch' className='fontBold col-sm-12 col-form-label'>
                    Dispatched Country <span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    type='text'
                    id='country_of_dispatch'
                    placeholder='Dispatched Country'
                    onChange={(event) => handleInputChange('country_of_dispatch', event.target.value)}
                    value={refundDetails.country_of_dispatch || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {dispatched?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.country_of_dispatch && <span style={{ color: 'red' }}>{errors.country_of_dispatch}</span>}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='order_date' className=' fontBold col-sm-12 col-form-label'>
                    Order Date<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='date'
                    id='order_date'
                    placeholder='Order Date'
                    onChange={(event) => handleInputChange('order_date', event.target.value)}
                    value={refundDetails.order_date || ''}
                    fullWidth
                  />
                  {errors.order_date && <span style={{ color: 'red' }}>{errors.order_date}</span>}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='date_of_refund' className=' fontBold col-sm-12 col-form-label'>
                    Date of Refund<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='date'
                    id='date_of_refund'
                    placeholder='Date Of Refund'
                    onChange={(event) => handleInputChange('date_of_refund', event.target.value)}
                    value={refundDetails.date_of_refund || ''}
                    fullWidth
                  />
                  {errors.date_of_refund && <span style={{ color: 'red' }}>{errors.date_of_refund}</span>}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='return_fee' className=' fontBold col-sm-12 col-form-label'>
                    Refund Amount<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='text'
                    id='return_fee'
                    placeholder='Refund Amount'
                    onChange={(event) => handleInputChange('return_fee', event.target.value)}
                    value={refundDetails.return_fee || ''}
                    fullWidth
                  />
                  {errors.return_fee && <span style={{ color: 'red' }}>{errors.return_fee}</span>}
                </CCol>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='transaction_type' className='fontBold col-sm-12 col-form-label'>
                    Transaction Type<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    id='transaction_type'
                    onChange={(event) => handleInputChange('transaction_type', event.target.value)}
                    value={refundDetails.transaction_type || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {transactionType?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.transaction_type && <span style={{ color: 'red' }}>{errors.transaction_type}</span>}
                </CCol>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='refund_transaction_type' className='fontBold col-sm-12 col-form-label'>
                    Refund Transaction Type<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    id='refund_transaction_type'
                    onChange={(event) => handleInputChange('refund_transaction_type', event.target.value)}
                    value={refundDetails.refund_transaction_type || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {accountType?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.refund_transaction_type && (
                    <span style={{ color: 'red' }}>{errors.refund_transaction_type}</span>
                  )}
                </CCol>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='currency' className='fontBold col-sm-12 col-form-label'>
                    Currency<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormSelect
                    id='currency'
                    onChange={(event) => handleInputChange('currency', event.target.value)}
                    value={refundDetails.currency || ''}
                    fullWidth
                  >
                    <option value=''>Choose...</option>
                    {currencySign?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                  {errors.currency && <span style={{ color: 'red' }}>{errors.currency}</span>}
                </CCol>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CCol sm={12}>
                  <CFormLabel htmlFor='status' className='fontBold col-sm-12 col-form-label'>
                    Status<span style={{ color: 'red' }}>*</span>
                  </CFormLabel>
                  <CFormInput
                    type='text'
                    id='status'
                    placeholder='Status'
                    onChange={(event) => handleInputChange('status', event.target.value)}
                    value={refundDetails.status || ''}
                    fullWidth
                  />
                  {errors.status && <span style={{ color: 'red' }}>{errors.status}</span>}
                </CCol>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 3, paddingBottom: 3 }}
            >
              <Button
                className='rounded-3 fs-16'
                variant='outlined'
                type='submit'
                style={{ width: 120, backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
              >
                {loading ? (
                  <CircularProgress size={27} style={{ marginLeft: '10px' }} />
                ) : (
                  <>
                    <RiRefundLine className='me-2' size='1.0rem' /> Submit
                  </>
                )}
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </section>
  )
}

export default RefundCommericalGesture

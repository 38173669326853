import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import PropTypes from 'prop-types'
import { concatStringIfMore, isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Avatar from 'components/uiElements/Avatar'


import './ChatListItem.css'
import moment from 'moment'

const ChatListItem = (props) => {
  const [isUnread, setIsUnread] = useState(props?.lastMessage?.isRead)
  const contextObj = useContext(UserContext)

  const updateMessage = () => {
    if (props.users) {
      const isUserInProps = props.users?.some(userId => String(userId) === String(contextObj?.userData?.user?.id))

      if (isUnread === false && isUserInProps) {
        props.socket.emit('UpdateMessage', {
          poId: props?.poId,
          ids: [props?.id]
        })

        setIsUnread(true)
      }
    }
  }


  useEffect(() => {
    updateMessage()
  }, [props.users])


  return (
    <li className={`p-2 border-bottom me-3`}>
      <div className={`d-flex justify-content-between ${isNotNullUndefinedOrEmpty(props.poId) && props.poId === contextObj.newParam.get('_id') && 'active-chat'}`}
        role='button'
        tabIndex={props?.id}
        data-testid='chatListItem'
        style={{ animationDelay: 0.5 }}
        onClick={() => {
          if (props.poId) {
            props.setMessage(props.poId, 0, 200, props.name)
            updateMessage()
            contextObj.navigate(`/messenger?_id=${props.poId}&name=${props.name}`)
          }
        }}
        onKeyDown={(e) => {
          e.preventDefault()
        }}
      >
        <div className='d-flex flex-row'>
          <div>
            <Avatar name={props?.lastMessage.userName} className='d-flex align-self-center me-3' width={60} height={60} />

          </div>
          <div className='pt-3'>
            <p className='fw-bold mb-0 user-name'>{concatStringIfMore(props?.name, 20)}</p>
            <p className='small text-muted'>
              {'company' === 'company' ? 'You:' : 'Buyer:'}
              {concatStringIfMore(props?.lastMessage.message, 35)}
            </p>
          </div>
        </div>
        <div className='pt-1'>
          <p className='small text-muted mb-1'>
            {moment(props.lastActive).fromNow()}
            {/* <TimeAgo date={props?.lastActive} /> */}
          </p>
          {props?.unreadCount > 0 && (
            <span className='badge bg-danger rounded-pill float-end'>{props?.unreadCount}</span>
          )}
          {isUnread === false && props?.lastMessage?.userId !== contextObj?.userData?.user?.id ? (
            <span className='badge bg-success rounded-pill float-end' style={{ fontSize: '10px', width: '12px', height: '12px' }}
            >&nbsp;</span>
          ) : ''}
        </div>
      </div>
    </li>
  )
}

export default ChatListItem

ChatListItem.propTypes = {
  animationDelay: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
  setMessage: PropTypes.func,
  chatListData: PropTypes.array,
  message: PropTypes.object,
  lastMessage: PropTypes.object,
  unreadCount: PropTypes.number,
  catalogueId: PropTypes.string,
  poId: PropTypes.string,
  lastActive: PropTypes.string,
  socket: PropTypes.object,
  users: PropTypes.array,

}

import React, { useState } from 'react'
import { BiSearchAlt } from 'react-icons/bi'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'

import ChatListItem from './ChatListItem'

import './ChatList.css'
import { Box, Tab, Tabs } from '@mui/material'

const ChatList = (props) => {
  const [SearchTerm, setSearchTerm] = useState('')
  return (
    <div className='p-3' data-testid='chatList'>
      <div className='input-group rounded mb-3'>
        <span className='input-group-text border-0' id='search-addon'>
          <BiSearchAlt />
        </span>
        <input
          type='search'
          className='form-control rounded'
          placeholder='Search Messages'
          aria-label='Search'
          aria-describedby='search-addon'
          onChange={(e) => {
            e.preventDefault()
            setSearchTerm(e.target.value)
          }}
        />
      </div>
      <hr />
      <Box sx={{ width: '100%' }}>
        <Tabs value={props.value} onChange={props.handleChange} aria-label='wrapped label tabs example'>
          <Tab value='Purchase Order' label='Purchase Order' />
          <Tab value='Project' label='Project' />
        </Tabs>
      </Box>
      <hr />
      <SimpleBar className='users-list'>
        <ul className='list-unstyled mb-0'>
          {props.chatListData !== undefined &&
            props.chatListData
              // eslint-disable-next-line array-callback-return
              .filter((val) => {
                if (val?.chatType === props.value && SearchTerm === '') {
                  return val
                } else if (
                  val?.chatType === props.value &&
                  val.poNumber.toLowerCase().includes(SearchTerm.toLowerCase())
                ) {
                  return val
                }
              })
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((item, index) => {
                return (
                  <ChatListItem
                    id={item._id}
                    poId={item.poId}
                    name={item.poNumber}
                    key={index}
                    animationDelay={index + 1}
                    setMessage={props.setMessage}
                    lastActive={item.updatedAt}
                    lastMessage={item.lastMessage}
                    isOpen={props.isOpen} // Pass isOpen
                    newMessage={props.newMessage} // Pass newMessage
                    socket={props.socket}
                    chatListData={props.chatListData}
                    users={props.users}
                  />
                )
              })}
        </ul>
      </SimpleBar>
    </div>
  )
}

export default ChatList

ChatList.propTypes = {
  chatListData: PropTypes.array,
  logo: PropTypes.string,
  setMessage: PropTypes.func,
  message: PropTypes.object,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  isOpen: PropTypes.bool,
  newMessage: PropTypes.number,
  socket: PropTypes.object,
  users: PropTypes.array,

}

import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  Skeleton,
  Button,
  TextField
} from '@mui/material'
import { useRefundListHooks } from './useRefundListHooks'
import Loader from 'components/common/Loader'
import Constants from 'data/Constants'
import RefundTable from './refundTable'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Seo from 'components/common/seo'

const SkeletonRow = ({ cols }) => (
  <TableRow>
    {Array.from({ length: cols }, (_, index) => (
      <TableCell key={index}>
        <Skeleton variant='text' />
      </TableCell>
    ))}
  </TableRow>
)

SkeletonRow.propTypes = {
  cols: PropTypes.number.isRequired
}

const RefundCommericalGestureList = () => {
  const {
    isLoading,
    newParam,
    skurowsPerPage,
    skupage,
    handleSkuChangePage,
    handleSkuChangeRowsPerPage,
    onChangeFilter,
    marketplace,
    resetFilter,
    refundList
  } = useRefundListHooks()
  const { transactionType, status } = Constants

  return (
    <>
      <Seo
        title='Return,Refund & Commercial Gesture'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />

      <section className='page-spacing'>
        <div className='container-fluid'>
          <div className='common-card rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8} lg={9}>
                  <h3 className="fw-bold advertisement-report">
                    Return, Refund & Commerical Gesture
                  </h3>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                  <Button className='mt-3' variant="outlined" component={Link} to="/refund/create">
                    Create Refund &nbsp;&nbsp;
                    <AiOutlineAppstoreAdd size="1.5rem" />
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('transaction_type')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected === null) {
                          return `Transaction Type`
                        }
                        return selected
                      }}
                      onChange={(e) => {
                        onChangeFilter('transaction_type', e.target.value)
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value=''>Transaction Type</MenuItem>
                      {transactionType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('refund_status')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected === null) {
                          return `Status`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeFilter('refund_status', e.target.value)
                      }}
                    >
                      <MenuItem value=''>Refund Status</MenuItem>
                      {status?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('marketplace_name')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected === null) {
                          return `Marketplace`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeFilter('marketplace_name', e.target.value)
                      }}
                    >
                      <MenuItem value=''>Marketplace</MenuItem>
                      {marketplace?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam?.get('start_date')
                          ? new Date(newParam.get('start_date')).toISOString().split('T')[0]
                          : newParam.get('start_date')
                      }
                      onChange={(e) => onChangeFilter('start_date', e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('end_date')
                          ? new Date(newParam.get('end_date')).toISOString().split('T')[0]
                          : newParam.get('end_date')
                      }
                      disabled={!newParam.get('start_date')}
                      onChange={(e) => onChangeFilter('end_date', e.target.value)}
                      min={new Date(newParam.get('start_date')).toISOString().split('T')[0]}
                      max={new Date().toISOString().split('T')[0]}
                      required
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1.3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      resetFilter()
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='row mt-4'>
                <div className='col-md-12 h-100'>
                  <div className='common-card rounded-3'>
                    <div className='common-card-title'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h5 className='fw-bold '>Return, Refund & Commerical Gesture Listing</h5>
                      </div>
                      <hr
                        style={{
                          color: '#000000',
                          backgroundColor: '#000000',
                          height: 0.5,
                          borderColor: '#000000'
                        }}
                      />
                    </div>
                    <div className='common-card-content border-0 mt-0 mb-0'>
                      <RefundTable
                        skupage={skupage}
                        skurowsPerPage={skurowsPerPage}
                        handleSkuChangePage={handleSkuChangePage}
                        handleSkuChangeRowsPerPage={handleSkuChangeRowsPerPage}
                        refundList={refundList?.result}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default RefundCommericalGestureList
